import { api } from './api';
import { MissionForm } from '../../globals';

const endpoints = (builder: any) => ({
  addMission: builder.mutation({
    query: ({ body }: { body: MissionForm }) => ({
      url: `mission`,
      method: 'POST',
      body: body,
    }),
    invalidatesTags: ['Mission'],
  }),
  getAllMissions: builder.query({
    query: () => `/mission`,
    providesTags: ['Mission'],
  }),
  getMissionById: builder.query({
    query: (id: string) => {
      return `/mission/${id}`;
    },
    providesTags: ['Mission'],
  }),
});

export const missionApi = api.injectEndpoints({ endpoints });
export const {
  useAddMissionMutation,
  useGetAllMissionsQuery,
  useGetMissionByIdQuery,
} = missionApi;
