import React from 'react';
import { RiContactsBookFill } from 'react-icons/ri';
import { BsFillGrid1X2Fill, BsBriefcaseFill } from 'react-icons/bs';
import { IoLogOut } from 'react-icons/io5';
import Logo from '../assets/logo_luter_final.png';
import { Link } from 'react-router-dom';
type Props = {
  missionname: string;
};

const SidebarClient: React.FC<Props> = ({ missionname }) => {
  return (
    <div className="inset-0 mx-8 mt-8 h-full z-50 w-fit hidden lg:flex">
      <div className="flex flex-col justify-between items-center w-64 h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6">
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="flex items-center justify-center gap-2">
            <img src={Logo} alt="Logo" className="w-10 h-auto" />
            <p className="text-primary text-4xl">luter</p>
          </div>
          <div className="text-center">
            <p className="text-xl mt-8 mb-2">Mission:</p>
            <p className="text-primary text-xl mb-4">{missionname}</p>
          </div>
          <div className="px-4 flex flex-col">
            <ul className="flex flex-col justify-center">
              <li className="mb-8">
                <Link to="/dashboard/client">
                  <button className="flex items-center justify-center hover:text-blue-500">
                    <BsBriefcaseFill size={30} className="mr-2 text-blue-500" />
                    <span className="text-xl">Avancement de la mission</span>
                  </button>
                </Link>
              </li>
              <li className="mb-8">
                <Link to="/dashboard/infofrellance">
                  <button className="flex items-center justify-center hover:text-blue-500">
                    <RiContactsBookFill
                      size={24}
                      className="mr-2 text-blue-500"
                    />
                    <span className="text-xl">Info Freelance</span>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarClient;
