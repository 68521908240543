import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { userApi } from '../services/auth';

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isLogged: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    autoLogin: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.user = user;
      state.isLogged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(userApi.endpoints.signIn.matchPending, () => {})
      .addMatcher(
        userApi.endpoints.signIn.matchFulfilled,
        (state: any, action: { payload: any }) => {
          console.log('loggingIn.matchFulfilled');
          const user = action?.payload;
          console.log(action?.payload);
          state.user = user;
          state.accessToken = user?.accessToken;
          state.refreshToken = user?.refreshToken;
          state.isLogged = true;
        }
      )
      .addMatcher(userApi.endpoints.signIn.matchRejected, () => {})
      .addMatcher(userApi.endpoints.signUpFreelance.matchPending, () => {})
      .addMatcher(
        userApi.endpoints.signUpFreelance.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const user = action?.payload;
          state.user = user;
          state.accessToken = user?.accessToken;
          state.refreshToken = user?.refreshToken;
          state.isLogged = true;
        }
      )
      .addMatcher(userApi.endpoints.signUpFreelance.matchRejected, () => {})
      .addMatcher(userApi.endpoints.signUpCompany.matchPending, () => {})
      .addMatcher(
        userApi.endpoints.signUpCompany.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const user = action?.payload;
          state.user = user;
          state.accessToken = user?.accessToken;
          state.refreshToken = user?.refreshToken;
          state.isLogged = true;
        }
      )
      .addMatcher(userApi.endpoints.signUpCompany.matchRejected, () => {})
      .addMatcher(
        (action) => action.type === 'user/autoLogin',
        (state, action) => {
          state.accessToken = action.payload;
          state.isLogged = true;
        }
      );
  },
});

export const { logout, autoLogin } = userSlice.actions;
export const selectUser = (state: any) => state?.user?.user;

export default userSlice.reducer;
