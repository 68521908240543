import React from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Spacer,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Th,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ClientNavbar from '../../Components/ClientNavbar';
import { type ClientType, QuotationLine } from '../../globals';

const dummyClients: ClientType[] = [
  {
    id: 1,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
  {
    id: 2,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
  {
    id: 3,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
];

const dummyQuotation: QuotationLine[] = [
  {
    id: 0,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 1,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 2,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 3,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 4,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 5,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 6,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
];

const dummyMission = {
  id: 1,
  client: dummyClients[0],
  quotation: dummyQuotation,
  price_ttc: 120,
  end_date: '2022-10-12',
  approval: 2,
};

const ClientMissionDetails = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <ClientNavbar />
      <Box justifyContent={'center'}>
        <Heading textAlign={'center'} my={5}>
          Détails de la mission
        </Heading>
        <Flex
          justifyContent={'space-around'}
          gap={15}
          marginX={10}
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Card w={'full'} h="fit-content">
            <CardHeader>
              <Heading size="md">Coordonnées</Heading>
            </CardHeader>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Text>
                  <b>Société:</b> {dummyMission.client.company_name}
                </Text>
                <Text>
                  <b>SIRET: </b>
                  {dummyMission.client.siret}
                </Text>
                <Text>
                  <b>Nom complet: </b>
                  {dummyMission.client.full_name}
                </Text>
                <Text>
                  <b>E-mail: </b>
                  {dummyMission.client.email}
                </Text>
                <Text>
                  <b>Addresse protefeuille: </b>
                  {dummyMission.client.wallet_address}
                </Text>
                <Text>
                  <b>Fonction: </b>
                  {dummyMission.client.job_position}
                </Text>
              </Stack>
            </CardBody>
          </Card>
          <Flex flexDirection={'column'} w="full" gap={15}>
            <Card w={'full'}>
              <CardHeader>
                <Heading size="md">Mission</Heading>
              </CardHeader>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Text>
                    <b>Prix TTC: </b>
                    {dummyMission.price_ttc}
                  </Text>
                  <Text>
                    <b>Validation: </b>
                    {dummyMission.approval}/2
                  </Text>
                </Stack>
              </CardBody>
            </Card>
            <Card w={'full'}>
              <Flex justifyContent={'center'}>
                <Box w="full" rounded="md" p={4}>
                  <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Devis
                  </Text>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Type</Th>
                        <Th>Quantité</Th>
                        <Th>Prix</Th>
                        <Th>Description</Th>
                        <Th>Price HT</Th>
                        <Th>Price TTC</Th>
                        <Th>TVA</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dummyMission.quotation.map(
                        (quotationLine: QuotationLine) => (
                          <Tr key={quotationLine.id}>
                            <Td>{quotationLine.type}</Td>
                            <Td>{quotationLine.quantity}</Td>
                            <Td>{quotationLine.unit_price}</Td>
                            <Td>{quotationLine.description}</Td>
                            <Td>{quotationLine.priceHT}</Td>
                            <Td>{quotationLine.priceTTC}</Td>
                            <Td>{quotationLine.TVA}</Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </Box>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} margin={10}>
          <Spacer />
          <Button colorScheme={'orange'}>
            <Link to={`/client/dashboard/${id}`}>Valider</Link>
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default ClientMissionDetails;
