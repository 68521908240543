import React, { useState } from 'react';
import FormulaStep from '../../Components/CreateMissionSteps/FormulaStep';
import PaymentMethod from '../../Components/CreateMissionSteps/PaymentMethod';
import MissionStep from '../../Components/CreateMissionSteps/MissionStep';
import CheckUp from '../../Components/CreateMissionSteps/CheckUp';

function CreateMission() {
  const [usage, setUsage] = useState('');
  const [subtitle, setSubtitle] = useState('Taux de commission');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [step, setStep] = useState('formula');
  const [data, setData] = useState({});

  const handleNext = () => {
    let newState = '';
    switch (step) {
      case 'formula':
        newState = 'paymentMethod';
        break;
      case 'paymentMethod':
        newState = 'missionStep';
        break;
      case 'missionStep':
        newState = 'checkup';
        break;
      default:
        newState = 'formula';
    }
    setStep(newState);
  };
  const handlePrevious = () => {
    let newState = '';
    switch (step) {
      case 'paymentMethod':
        newState = 'formula';
        break;
      case 'missionStep':
        newState = 'paymentMethod';
        break;
      case 'checkup':
        newState = 'missionStep';
        break;
      default:
        newState = 'formula';
    }
    setStep(newState);
  };

  return (
    <div className="pl-8">
      {step === 'formula' && (
        <FormulaStep
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {step === 'paymentMethod' && (
        <PaymentMethod
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {step === 'missionStep' && (
        <MissionStep
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {step === 'checkup' && (
        <CheckUp
          data={data}
          setData={setData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
    </div>
  );
}

export default CreateMission;
