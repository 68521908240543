import React, { useState, useEffect } from 'react';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';
import PopUp from '../PopUp';

function RegisterTarification({ onNext, onPrev }: any) {
  const [usage, setUsage] = useState('');
  const [subtitle, setSubtitle] = useState('Taux de commission');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsage(event.target.value);
    if (event.target.value === 'Taux de commission') {
      setSubtitle('Taux de commission');
    } else if (event.target.value === 'Abonnement mensuel') {
      setSubtitle('Abonnement mensuel');
    }
  };
  const handleNext = () => {
    if (usage) {
      onNext();
    } else {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row"
        style={{ height: '100%', padding: 30, paddingTop: '110px' }}
      >
        <div className="w-full md:w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent={50} className="absolute top-0 left-0" />
          <div className="flex flex-col gap-6 pt-10 justify-center h-full">
            <h1 className="text-2xl font-bold">Quel usage prévoyez-vous ?</h1>
            <label className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
              <input
                type="radio"
                className="form-radio"
                value="Taux de commission"
                onChange={handleRadioChange}
                checked={usage === 'Taux de commission'}
                required
              />
              <span className="text-lg font-medium pl-2">
                Taux de commission
              </span>
            </label>
            <label className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
              <input
                type="radio"
                className="form-radio"
                value="Abonnement mensuel"
                onChange={handleRadioChange}
                checked={usage === 'Abonnement mensuel'}
                required
              />
              <span className="text-lg font-medium pl-2">
                Abonnement mensuel
              </span>
            </label>
            <div className="mt-20 text-right flex items-center justify-between">
              <button
                onClick={onPrev}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Précédent
              </button>
              <button
                onClick={handleNext}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
        {windowWidth > 768 && (
          <div className="w-2/5 pl-4" style={{ height: '100%' }}>
            <BulleForm
              title="Laissez-nous vous proposer des solutions sur-mesure"
              subtitle={subtitle}
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec acc"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterTarification;
