import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { missionApi } from '../services/missions';

const initialState = {
  lastCreatedMission: {},
};

const missionSlice = createSlice({
  name: 'missions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(missionApi.endpoints.addMission.matchPending, () => {})
      .addMatcher(
        missionApi.endpoints.addMission.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const mission = action?.payload;
        }
      )
      .addMatcher(missionApi.endpoints.addMission.matchRejected, () => {});
  },
});

export default missionSlice.reducer;
