import React, { useState } from 'react';
import MissionBulle from '../MissionBulle';
import PopUp from '../PopUp';
import ProgressBar from '../ProgressBar';

function CreateMission({ data, setData, handleNext, handlePrevious }: any) {
  const [usage, setUsage] = useState('');
  const [subtitle, setSubtitle] = useState(
    'Pourquoi ce choix est primordial ?'
  );
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState({});

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsage(event.target.value);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row"
        style={{ height: '100%', padding: 30 }}
      >
        <div className="w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent="50" />
          <div className="flex flex-col gap-6" style={{ height: '100%' }}>
            <h1 className="text-2xl font-bold my-16">
              Sélectionnez les formules de paiement acceptées pour votre
              prestation
            </h1>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Progress') ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="Progress"
                onChange={handleCheckboxChange}
                checked={usage.includes('Progress')}
              />
              <span
                className={`text-lg font-medium pl-2 ${
                  usage.includes('Progress') ? 'text-blue-500' : ''
                }`}
              >
                Facturation progressive
              </span>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Crypto') ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="Crypto"
                onChange={handleCheckboxChange}
                checked={usage.includes('Crypto')}
              />
              <span
                className={`text-lg font-medium pl-2 ${
                  usage.includes('Crypto') ? 'text-blue-500' : ''
                }`}
              >
                Cryptomonaie
              </span>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Funds') ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="Funds"
                onChange={handleCheckboxChange}
                checked={usage.includes('Funds')}
              />
              <span
                className={`text-lg font-medium pl-2 ${
                  usage.includes('Funds') ? 'text-blue-500' : ''
                }`}
              >
                Séquestration de fonds
              </span>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Print') ? 'selected' : ''
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox"
                value="Print"
                onChange={handleCheckboxChange}
                checked={usage.includes('Print')}
              />
              <span
                className={`text-lg font-medium pl-2 ${
                  usage.includes('Print') ? 'text-blue-500' : ''
                }`}
              >
                Empreinte bancaire
              </span>
            </label>
          </div>
          <div className="mt-20 text-right flex items-center justify-between">
            <button
              onClick={handlePrevious}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Retour
            </button>
            <button
              onClick={handleNext}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Suivant
            </button>
          </div>
        </div>
        <div className="w-2/5 pl-4" style={{ height: '100%' }}>
          <MissionBulle
            subtitle={subtitle}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec acc"
          />
        </div>
      </div>
    </div>
  );
}

export default CreateMission;
