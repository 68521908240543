import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Spline from '@splinetool/react-spline';
import '../styles.css';

// Images
import Wave from '../assets/wave.png';
import tempWindow from '../assets/temp_window.png';
import relaxLanding from '../assets/relax_landing.png';
import dashboard from '../assets/dashboard.png';

// Icons
import {
  BsShieldLock,
  BsFillGearFill,
  BsChatDots,
  BsPen,
} from 'react-icons/bs';
import { FaHandsHelping } from 'react-icons/fa';
import { GiWhistle } from 'react-icons/gi';
import { SlNotebook } from 'react-icons/sl';
import { VscLaw } from 'react-icons/vsc';
import { GrValidate } from 'react-icons/gr';
import {
  AiFillThunderbolt,
  AiOutlinePlus,
  AiOutlineCheckCircle,
  AiOutlineFileSearch,
  AiOutlineSafetyCertificate,
} from 'react-icons/ai';

// Type
import { IconType } from 'react-icons';

// Hooks
import { InView } from 'react-intersection-observer';

// Components
import Hero from '../Components/Hero';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import PricingCard from '../Components/Pricing';

type InfoDropdownProps = {
  title: string;
  details: string;
};

type LitigeDetailsProps = {
  index: number;
  title: string;
  Icon: IconType;
  description: string;
};

const InfoDropdown = ({ title, details }: InfoDropdownProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex justify-start items-/Users/matheorobert/Desktop/epi/Luter-front/Luter-smart-contract gap-4">
      <div className="flex flex-col justify-start items-start gap-2 w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-white font-bold">{title}</h1>
          <button
            className="flex justify-center items-center bg-primary rounded-full min-w-[40px] min-h-[40px]"
            onClick={() => setOpen(!open)}
          >
            <span
              className={`transition-all duration-300 transform ${
                open ? 'rotate-45' : 'rotate-0'
              }`}
            >
              <AiOutlinePlus size={20} color="#fff" />
            </span>
          </button>
        </div>
        {open && <p className="text-white font-light">{details}</p>}

        <hr className="border-[#fff] border-opacity-70 w-full" />
      </div>
    </div>
  );
};

const LitigeDetails = ({
  index,
  title,
  Icon,
  description,
}: LitigeDetailsProps) => {
  return (
    <div className="flex flex-col justify-around gap-8">
      <div className="flex justify-between gap-4">
        <span className="flex justify-start items-center">
          <p className="text-white bg-primary rounded-xl w-10 py-1 flex justify-center items-center font-bold text-2xl">
            {index}
          </p>
          <h1 className="font-bold ml-4">{title}</h1>
        </span>
        <Icon size={30} color="#1552F0" />
      </div>
      <p>{description}</p>
      <hr className="border-black border-opacity-70 w-full" />
    </div>
  );
};

function App() {
  const handleCTAClick = () => {
    // Gérer le clic sur le bouton CTA
  };
  const navigate = useNavigate();
  return (
    <div>
      <Navbar isLogged={true} />
      <div
        className="
                relative
                    min-h-[90vh]
                    w-full
                    bg-gradient-to-r from-[#1552F0] to-[#16A0F0]
                    flex justify-between items-center h-full
                    z-10
                "
      >
        <div className="flex flex-col gap-6 md:gap-0 md:flex-row justify-between items-center w-full h-full px-8">
          <Hero />
        </div>
        <img
          src={Wave}
          alt="wave"
          className="absolute bottom-0 right-0 w-full"
        />
      </div>
      <div className="relative z-10 mb-10">
        <div className="absolute z-0 left-[3.75rem] top-[-5vh] md:top-[-170px] bg-primary  w-[3px] lg:w-[4px] h-[20vh] md:h-[200px] flex justify-center items-center"></div>
        {/* Sécurité */}
        <section className="relative mx-6 z-10 mb-10" id="about">
          <div className="flex justify-start gap-4 items-stretch">
            <InView trackVisibility delay={1500}>
              {({ inView, ref, entry }) => (
                <div className="flex flex-col items-center" ref={ref}>
                  <div
                    className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${
                      inView ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <BsShieldLock size={40} color="#fff" />
                  </div>
                  <div
                    className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${
                      inView ? 'h-full' : 'h-0'
                    }`}
                  ></div>
                </div>
              )}
            </InView>
            <div className="flex flex-col gap-4">
              <div className="text-4xl flex flex-col gap-2">
                <motion.p
                  className="text-primary font-light"
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  Sécurité
                </motion.p>
                <motion.h1
                  className="text-primary font-bold"
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.3 }}
                >
                  La tranquillité d&apos;esprit assurée avec Luter
                </motion.h1>
                <motion.h1
                  className=""
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.4 }}
                >
                  Aucune mission impayée.
                </motion.h1>
                <motion.h1
                  className=""
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.5 }}
                >
                  Aucun retard de paiement.
                </motion.h1>
              </div>
              <div className="xs:hidden sm:block md:block ml-auto">
                <img src={dashboard} alt="demo" />
              </div>
            </div>
          </div>
        </section>
        {/* Simplicité */}
        <section className="relative mx-6 z-10 mb-10">
          <div className="flex justify-start gap-4 items-stretch">
            <InView trackVisibility delay={1500}>
              {({ inView, ref, entry }) => (
                <div className="flex flex-col items-center" ref={ref}>
                  <div
                    className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${
                      inView ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <AiFillThunderbolt size={40} color="#fff" />
                  </div>
                  <div
                    className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${
                      inView ? 'h-full' : 'h-0'
                    }`}
                  ></div>
                </div>
              )}
            </InView>
            <div className="flex flex-col gap-4 flex-wrap">
              <div className="text-4xl flex flex-col gap-2">
                <motion.p
                  className="text-primary font-light"
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  Simplicité
                </motion.p>
                <motion.h1
                  className="text-primary font-bold"
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.3 }}
                >
                  Focalisez-vous sur l&apos;essentiel avec Luter
                </motion.h1>
                <motion.h1
                  className=""
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.4 }}
                >
                  Invitez vos clients, signez le cahier des charges et
                  confiez-nous le reste.
                </motion.h1>
              </div>
            </div>
            <div className="flex flex-wrap xs:hidden sm:hidden md:block">
              <Spline
                scene="https://prod.spline.design/3OKn11ZHzmJTTes3/scene.splinecode"
                className="lg:scale-100 md:m-0 md:scale-50 sm:-mx-10 sm:scale-25"
              />
            </div>
          </div>
        </section>
        {/* Accompagnement */}
        <section className="relative mx-6 z-10 mb-10">
          <div className="flex justify-start gap-4 items-stretch">
            <InView trackVisibility delay={1500}>
              {({ inView, ref, entry }) => (
                <div className="flex flex-col items-center" ref={ref}>
                  <div
                    className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${
                      inView ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <FaHandsHelping size={40} color="#fff" />
                  </div>
                  <div
                    className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${
                      inView ? 'h-full' : 'h-0'
                    }`}
                  ></div>
                </div>
              )}
            </InView>
            <div className="flex flex-col gap-4 flex-wrap">
              <div className="text-4xl flex flex-col gap-2">
                <motion.p
                  className="text-primary font-light"
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  Accompagnement
                </motion.p>
                <motion.h1
                  className="text-primary font-bold"
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.3 }}
                >
                  Soyez maître de votre travail avec Luter
                </motion.h1>
                <motion.h1
                  className=""
                  initial={{ opacity: 0, y: 20 }}
                  animate={InView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.3, delay: 0.4 }}
                >
                  Profitez d&apos;un espace partagé avec votre client et
                  d&apos;un accompagnement adapté pour chaque échange.
                </motion.h1>
              </div>
            </div>
            <div className="xs:hidden sm:hidden md:block ml-auto">
              <img src={tempWindow} alt="demo" />
            </div>
          </div>
        </section>

        {/* Modularité */}
        <section className="relative mx-6 z-10 mb-10">
          <div className="flex justify-start gap-4 items-stretch">
            <InView trackVisibility delay={1500}>
              {({ inView, ref, entry }) => (
                <div className="flex flex-col items-center" ref={ref}>
                  <div
                    className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${
                      inView ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <BsFillGearFill size={40} color="#fff" />
                  </div>
                  <div
                    className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${
                      inView ? 'h-full' : 'h-0'
                    }`}
                  ></div>
                </div>
              )}
            </InView>
            <div className="flex flex-col gap-4">
              <div className="text-4xl flex flex-col gap-2">
                <p className="text-primary font-light">Modularité</p>
                <h1 className="text-primary font-bold">
                  Personnalisez votre utilisation de Luter
                </h1>
                <h1 className="">
                  Sélectionnez les fonctionnalités qui répondent à vos besoins.
                </h1>
              </div>
            </div>
            <div className="xs:hidden sm:hidden md:block ml-auto">
              <img src={relaxLanding} alt="demo" className="ml-auto" />
            </div>
          </div>
        </section>
        {/* Tarifs */}
        <section className="mx-6">
          <h1 className="font-bold text-4xl mb-4 mt-5 text-secondary text-center">
            Trouvez la <span className="text-primary">couverture</span> pensée
            pour vous.
          </h1>
          <div className="flex xs:flex-col md:flex-row justify-center">
            {/* flex md:flex-row xs:flex-col justify-center items-center h-screen max-h-screen xs:my-96 */}
            <PricingCard
              title="Green"
              description="Une formule d'entrée de gamme."
              price="2%"
              advantages={[
                { text: 'Génération de smart contracts', icon: BsPen },
                { text: 'Génération du cahier des charges', icon: SlNotebook },
                {
                  text: 'Séquestration de fonds',
                  icon: AiOutlineSafetyCertificate,
                },
              ]}
              ctaLabel="Essayer Luter Green"
              ctaOnClick={handleCTAClick}
            />
            <PricingCard
              title="Blue"
              description="Une formule adaptée aux besoins habituels."
              price="7%"
              advantages={[
                { text: 'Génération de smart contracts', icon: BsPen },
                { text: 'Génération du cahier des charges', icon: SlNotebook },
                {
                  text: 'Séquestration de fonds',
                  icon: AiOutlineSafetyCertificate,
                },
                { text: 'Gestion litiges < 5k€', icon: VscLaw },
                { text: 'Validation cahier des charges', icon: GrValidate },
              ]}
              ctaLabel="Souscrire"
              ctaOnClick={handleCTAClick}
            />
            <PricingCard
              title="Purple"
              description="Une formule exclusive adaptée à certains besoins."
              price="11%"
              advantages={[
                { text: 'Génération de smart contracts', icon: BsPen },
                { text: 'Génération du cahier des charges', icon: SlNotebook },
                {
                  text: 'Séquestration de fonds',
                  icon: AiOutlineSafetyCertificate,
                },
                { text: 'Gestion litiges > 5k€', icon: VscLaw },
                { text: 'Validation cahier des charges', icon: GrValidate },
              ]}
              ctaLabel="Souscrire"
              ctaOnClick={handleCTAClick}
            />
          </div>
          <motion.div
            className="flex flex-col items-center mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <motion.h2
              className="text-3xl font-bold text-secondary"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Besoin d&apos;orientation?
            </motion.h2>
            <motion.button
              className="px-6 py-3 mt-4 bg-primary text-white rounded-full focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Remplir le formulaire
            </motion.button>
          </motion.div>
        </section>
        <section className="bg-primary flex md:flex-row gap-6 items-center flex-col justify-around text-white p-6 my-10">
          <div className="flex flex-col justify-center items-start md:w-1/2">
            <h1 className="font-bold text-4xl mb-4">Nos engagements</h1>
            <p>
              Chez Luter, nous travaillons chaque jour pour offrir un service
              d&apos;échange de fonds et de prestation de services sécurisé et
              optimisé pour les freelances et leurs clients.
            </p>
          </div>
          <div className="w-full md:w-fit">
            <InfoDropdown
              title="Sécurité pour vous et votre client"
              details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod."
            />
            <InfoDropdown
              title="Accompagnement personnalisé"
              details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod."
            />
            <InfoDropdown
              title="Plateforme innovante"
              details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod."
            />
            <InfoDropdown
              title="Éthique et transparence"
              details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod."
            />
          </div>
        </section>
        <section className="mx-6">
          <h1 className="font-bold text-4xl mb-4 text-primary text-center">
            Et en cas de litige ?
          </h1>
          <p className="text-center text-md text-gray-500 mb-10">
            Nous avons mis en place un processus en 4 étapes pour aider à
            résoudre les litiges qui peuvent survenir entre les clients et les
            freelances sur notre plateforme.
          </p>
          <div
            className="
                        grid
                        grid-cols-1
                        md:grid-cols-2
                        w-full
                        gap-4
                        p-6
                    "
          >
            <LitigeDetails
              index={1}
              Icon={AiOutlineFileSearch}
              title="Évaluation"
              description="Lorsqu'un litige est déclaré, notre équipe d'assistance technique examine les preuves soumises par les deux parties pour évaluer la situation."
            />
            <LitigeDetails
              index={2}
              Icon={BsChatDots}
              title="Médiation"
              description="Si le litige ne peut pas être résolu par notre équipe d'évaluation, nous avons des médiateurs disponibles pour aider à négocier un règlement équitable pour les deux parties."
            />
            <LitigeDetails
              index={3}
              Icon={GiWhistle}
              title="Arbitrage"
              description="Si la médiation échoue, nous proposons une option d'arbitrage pour les clients et les freelances. Cette étape est plus formelle et implique un tiers indépendant qui évalue les preuves et rend une décision."
            />
            <LitigeDetails
              index={4}
              Icon={AiOutlineCheckCircle}
              title="Résolution"
              description="Une fois qu'une décision a été prise, nous aidons les parties à mettre en œuvre la décision et à résoudre le litige de manière satisfaisante."
            />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default App;
