import React, { useState, useEffect, useCallback } from 'react';
import displayWarningToast from '../utils/displayWarningToast';
import { useSignInMutation } from '../redux/services/auth';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LoginForm = () => {
  const [signIn, response] = useSignInMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return new Promise<string>((resolve, reject) => {
      setIsLoading(true);
      const body = {
        email: email,
        password: password,
      };
      signIn(body)
        .unwrap()
        .then((res: any) => {
          // Redirect to the dashboard
          setIsLoading(false);
          navigate('/dashboard');
          resolve('Success');
        })
        .catch((err: any) => {
          console.log(err);
          // Check if the error is a 400 error
          if (err.status === 400) {
            // Display the error message
            displayWarningToast(err.data.error, 'errorLoggingIn');
          }
          setIsLoading(false);
          reject(err.data.error ? err.data.error : err);
        });
    });
  }, []);

  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    if (user.accessToken != null && user.accessToken !== '') {
      navigate('/dashboard');
    }
    return () => {
      user;
    };
  }, [user]);

  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
        <h5 className="text-xl font-medium text-gray-900">
          Sign in to our platform
        </h5>
        <form onSubmit={handleSubmit} className="bg-white px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              placeholder="test@test.com"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              placeholder="*******"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="flex items-center justify-between py-5">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {isLoading ? (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                'Sign In'
              )}
            </button>
            <button
              onClick={() => {
                window.location.href = '/register';
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Register
            </button>
          </div>
          <div className="flex items-start mb-6">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  required
                />
              </div>
              <label
                htmlFor="remember"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 pr-1"
              >
                Remember me
              </label>
            </div>
            <a
              href="#"
              className="ml-auto text-sm text-blue-700 hover:underline dark:text-blue-500"
            >
              Lost Password?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
