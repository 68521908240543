import React, { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import {
  Flex,
  Card,
  Heading,
  Button,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import { ClientType } from '../../globals';
import { useAddClientMutation } from '../../redux/services/clients';
import { useNavigate } from 'react-router-dom';

import displaySuccessToast from '../../utils/displaySuccessToast';
import displayWarningToast from '../../utils/displayWarningToast';

const AddClient = () => {
  const [companyName, setCompanyName] = useState<string>('IncroyableCorp');
  const [siret, setSiret] = useState<string>('382938492839485');
  const [fullName, setFullname] = useState<string>('Jean Test');
  const [jobPosition, setJobPosition] = useState<string>('CEO');
  const [email, setEmail] = useState<string>('jeanjeanjeantyopr@yopmail.com');
  const [walletAddress, setWalletAddress] = useState<string>(
    '0x5586CFb2f128Edb244B54AdC63896C0766429030'
  );
  const [businessAddress, setBusinessAddress] =
    useState<string>('2 rue des fleurs');
  const [addClient, response] = useAddClientMutation();
  const navigate = useNavigate();
  const handleClientCreation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body: ClientType = {
      wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
      company_name: companyName,
      email: email,
      siret: siret,
      full_name: fullName,
      job_position: jobPosition,
      tva_number: '123456789',
    };

    addClient({ body: body })
      .unwrap()
      .then((res: any) => {
        displaySuccessToast('Client créé avec succès', 'clientCreated');
        navigate('/dashboard');
      })
      .catch((err: any) => {
        console.log(body);
        displayWarningToast('Une erreur est survenue', 'errorCreatingClient');
      });
  };

  return (
    <>
      <Navbar isLogged={true} />
      <Card p={10} m={10}>
        <Heading mb={10} size={'xl'}>
          Créer un client
        </Heading>
        <form onSubmit={handleClientCreation}>
          <Flex flexDirection={'column'} gap={4}>
            <FormControl isRequired id="companyName">
              <FormLabel>Nom de la société</FormLabel>
              <Input
                type="text"
                placeholder="Luter"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired id="siret">
              <FormLabel>SIRET</FormLabel>
              <Input
                type="text"
                placeholder="FR123456789"
                value={siret}
                onChange={(e) => setSiret(e.target.value)}
                maxLength={14}
                minLength={14}
              />
            </FormControl>
            <FormControl isRequired id="siret">
              <FormLabel>Adresse portefeuille</FormLabel>
              <Input
                type="text"
                placeholder="0x5586CFb2f128Edb244B54AdC63896C0766429030"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired id="fullName">
              <FormLabel>Nom & Prénom</FormLabel>
              <Input
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullname(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired id="fullName">
              <FormLabel>E-mail</FormLabel>
              <Input
                type="email"
                placeholder="test@test.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired id="fullName">
              <FormLabel>Adresse de la société</FormLabel>
              <Input
                type="address"
                placeholder="24 rue de la paix"
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired id="jobPosition">
              <FormLabel>Fonction</FormLabel>
              <Input
                type="text"
                placeholder="Président"
                value={jobPosition}
                onChange={(e) => setJobPosition(e.target.value)}
              />
            </FormControl>
            <Button
              disabled={
                companyName === '' ||
                siret === '' ||
                siret.length < 14 ||
                fullName === '' ||
                jobPosition === ''
              }
              type="submit"
              variant={'solid'}
              colorScheme={'orange'}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Card>
      <Footer />
    </>
  );
};

export default AddClient;
