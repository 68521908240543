import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface IncomeChartData {
  month: string;
  income: number;
}

interface IncomeChartProps {
  data: IncomeChartData[];
}

const IncomeChart: React.FC<IncomeChartProps> = ({ data }) => {
  const chartOptions: any = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      colors: ['#4285F4'],
    },
    xaxis: {
      categories: data.map((item) => item.month),
      labels: {
        style: {
          colors: ['#2E2E2E'],
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        style: {
          color: '#2E2E2E',
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          colors: ['#2E2E2E'],
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
    markers: {
      size: 4,
      colors: ['#4285F4'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `€${val}`;
        },
      },
    },
    title: {
      text: 'Revenus',
      align: 'left',
      style: {
        fontSize: '16px',
        color: '#2E2E2E',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 'bold',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Revenus',
      data: data.map((item) => item.income),
    },
  ];

  return (
    <div className="w-full">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default IncomeChart;
