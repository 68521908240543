import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import Business from '../../assets/business_V2.png';
import Freelance from '../../assets/freelance_V2.png';

type Props = {};

const Register = (props: Props) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Navbar isLogged={false} />
      <div className="flex flex-col justify-center items-center h-screen gap-10">
        <div className="flex flex-col justify-center items-center h-3/4 gap-10">
          <h1 className="text-4xl font-bold">Quelle est votre situation ?</h1>
          <div className="flex flex-col sm:flex-row justify-between items-center w-full gap-10">
            <Link
              to={'/register/freelance'}
              className="flex flex-row justify-between items-center shadow-md rounded-md p-10 hover:shadow-lg transition-all duration-200 bg-blue-600 w-full sm:w-1/2 mb-4 sm:mb-0"
            >
              <img src={Freelance} alt="business" className="w-40" />
              <div className="flex flex-col justify-center items-start">
                <div className="flex flex-row justify-start items-center">
                  <h1 className="text-2xl font-bold text-white">Freelance</h1>
                </div>
                <p className="text-white">
                  Je souhaite profiter de Luter avec mes clients
                </p>
              </div>
            </Link>
            <Link
              to={'/register/api'}
              className="flex flex-row justify-between items-center shadow-md rounded-md p-10 hover:shadow-lg transition-all duration-200 bg-blue-600 w-full sm:w-1/2"
            >
              <img src={Business} alt="business" className="w-40" />
              <div className="flex flex-col justify-center items-start">
                <div className="flex flex-row justify-start items-center">
                  <h1 className="text-2xl font-bold text-white">Entreprise</h1>
                </div>
                <p className="text-white">
                  Je souhaite accéder à l&apos;API et au white label
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {isMobile && <div className="mb-8"></div>}
      <Footer />
    </>
  );
};

export default Register;
