import React from 'react';
import Logo from '../assets/white_logo.png';
import LogoV2 from '../assets/logo-v2.png';

type Props = {
  title: string;
  subtitle: string;
  text: string;
};

const BulleForm = ({ title, subtitle, text }: Props) => {
  return (
    <div className="flex flex-col pl-4 bg-gradient-to-br from-blue-400 bg-blue-600 relative rounded-3xl h-full max-w-md mx-auto overflow-hidden">
      <div className="flex flex-col h-full p-4 justify-between">
        <div className="flex justify-end">
          <div className="p-2 flex flex-col items-center">
            <img src={Logo} alt="logo" style={{ width: '30px' }} />
            <img src={LogoV2} alt="logo" style={{ width: '40px' }} />
          </div>
        </div>
        <div className="flex-grow flex items-center overflow-hidden">
          <div className="text-center w-full">
            <h2 className="text-white font-bold text-xl text-center pb-10 md:pb-20">
              {title}
            </h2>
            <div className="flex flex-col md:flex-row md:justify-center">
              <div className="bg-white/30 back-blur-md rounded-lg px-4 py-2 md:pb-5 md:mr-5 md:max-w-xs">
                <h3 className="text-white font-bold text-lg text-center pb-5 md:pb-0">
                  {subtitle}
                </h3>
                <p className="text-white text-sm mt-1">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulleForm;
