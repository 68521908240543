import React, { useState } from 'react';
import Logo from '../assets/white_logo.png';
import LogoV2 from '../assets/logo-v2.png';

type Props = {
  subtitle: string;
  text: string;
};

const GeneralInfo = ({ subtitle, text }: Props) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex flex-col bg-primary relative rounded-3xl h-5/6 lg:mx-8 md:mx-4 sm:mx-2 overflow-hidden">
      <div className="flex flex-col h-full p-4 justify-between">
        <div className="flex justify-center">
          <h1 className="text-white lg:text-4xl md:text-2xl sm:text-xl text-center mt-4">
            Informations générales
          </h1>
        </div>
        <div className="overflow-hidden justify-center">
          <div className="bg-white mb-4 rounded-md p-2">Couverture:</div>
          <div className="bg-white mb-4 rounded-md p-2">
            <input type="text" placeholder="Nom de la mission" />
          </div>
          <div className="bg-white mb-4 rounded-md p-2">Date de livraison:</div>
          <div className="bg-white mb-4 rounded-md p-2">
            <select
              value={selectedOption}
              onChange={handleChange}
              className="bg-white"
            >
              <option value="">Sélectionnez un client</option>
              <option value="client 1">Client 1</option>
            </select>
          </div>
          <div className="bg-white rounded-md">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="pl-2 py-2 text-left">
                    Estimation de votre CA
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2">Total (HT)</td>
                  <td className="px-4 py-2 text-right">0.00€</td>
                </tr>
                <tr>
                  <td className="border-t border-gray-300 px-4 py-2">TVA</td>
                  <td className="border-t border-gray-300 px-4 py-2 text-right">
                    20%
                  </td>
                </tr>
                <tr>
                  <td className="border-t border-gray-300 px-4 py-2">
                    Frais Luter ()
                  </td>
                  <td className="border-t border-gray-300 px-4 py-2 text-right">
                    0.00€
                  </td>
                </tr>
                <tr>
                  <td className="border-t border-gray-300 px-4 py-2">
                    Vous recevrez (TTC)
                  </td>
                  <td className="border-t border-gray-300 px-4 py-2 text-right">
                    0.00€
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
