import React, { useState } from 'react';
import MissionBulle from '../MissionBulle';
import PopUp from '../PopUp';
import DashboardLayout from '../../Layout/DashboardLayout';
import ProgressBar from '../ProgressBar';
import { Link } from 'react-router-dom';

function CreateMission({ data, setData, handleNext, handlePrevious }: any) {
  const [usage, setUsage] = useState('');
  const [subtitle, setSubtitle] = useState('Formule Green');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState({});

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsage(event.target.value);
    if (event.target.value === 'Green') {
      setSubtitle('Formule Green');
    } else if (event.target.value === 'Blue') {
      setSubtitle('Formule Blue');
    } else if (event.target.value === 'Purple') {
      setSubtitle('Formule Purple');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row"
        style={{ height: '100%', padding: 30 }}
      >
        <div className="w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent="25" />
          <div className="flex flex-col gap-6" style={{ height: '100%' }}>
            <h1 className="text-2xl font-bold my-16">
              Quelle formule vous conviendrait ?
            </h1>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Green') ? 'selected' : ''
              }`}
            >
              <input
                type="radio"
                className="form-radio"
                value="Green"
                onChange={handleRadioChange}
                checked={usage === 'Green'}
                required
              />
              <span className="text-lg font-medium pl-2 text-green-500">
                Green
              </span>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Blue') ? 'selected' : ''
              }`}
            >
              <input
                type="radio"
                className="form-radio"
                value="Blue"
                onChange={handleRadioChange}
                checked={usage === 'Blue'}
                required
              />
              <span className="text-lg font-medium pl-2 text-blue-500">
                Blue
              </span>
            </label>
            <label
              className={`bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 checkbox-label ${
                usage.includes('Purple') ? 'selected' : ''
              }`}
            >
              <input
                type="radio"
                className="form-radio"
                value="Purple"
                onChange={handleRadioChange}
                checked={usage === 'Purple'}
                required
              />
              <span className="text-lg font-medium pl-2 text-purple-500">
                Purple
              </span>
            </label>
          </div>
          <div className="mt-20 text-right flex items-center justify-between">
            <Link to="/dashboard">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Retour
              </button>
            </Link>
            <button
              onClick={handleNext}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Suivant
            </button>
          </div>
        </div>
        <div className="w-2/5 pl-4" style={{ height: '100%' }}>
          <MissionBulle
            subtitle={subtitle}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec acc"
          />
        </div>
      </div>
    </div>
  );
}

export default CreateMission;
