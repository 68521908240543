import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { type ClientType, QuotationLine } from '../../globals';
import {
  Flex,
  Text,
  Badge,
  Avatar,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Heading,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';

import contractHandleEndMission from '../../utils/contract/handleEndMission';

const dummyClients: ClientType[] = [
  {
    id: 1,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
  {
    id: 2,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
  {
    id: 3,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
];

const dummyQuotation: QuotationLine[] = [
  {
    id: 0,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 1,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 2,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 3,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 4,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 5,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 6,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
];

const dummyMission = {
  id: 1,
  client: dummyClients[0],
  quotation: dummyQuotation,
  price_ttc: 120,
  end_date: '2022-10-12',
  approval: 2,
};

import { useGetMissionByIdQuery } from '../../redux/services/missions';
import moment from 'moment';
import { Link } from '@chakra-ui/react';
const MissionDetails = () => {
  const { id } = useParams();
  const { data: missions } = useGetMissionByIdQuery<any>(id);
  const { library: provider } = useWeb3React();
  const isEnded = dummyMission.end_date < new Date().toISOString();
  const [isLoading, setIsLoading] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleUpdateMission = useCallback(() => {
    console.log('Update mission');
  }, []);

  const handleEndMission = useCallback(async () => {
    setIsLoading(true);

    try {
      contractHandleEndMission(
        provider,
        id as string,
        setTxHash,
        setIsSuccess,
        navigate
      );
    } catch (e: any) {
      setError(e.message);
      console.log('error: ', e);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
    setIsLoading(false);
  }, [provider, id, navigate]);

  return (
    <div>
      <Navbar isLogged={true} />
      <Text fontSize="4xl" textAlign="center" fontWeight={'bold'}>
        Mission #{id}
      </Text>
      {error && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      {isSuccess && (
        <Alert status="success">
          <AlertIcon />
          La mission a bien été terminée
        </Alert>
      )}
      {isLoading && !error && (
        <Alert status="info">
          <AlertIcon />
          En attente de confirmation de la transaction
        </Alert>
      )}
      {txHash && (
        <Alert status="info">
          <AlertIcon />
          <a href={`https://rinkeby.etherscan.io/tx/${txHash}`}>
            Transaction en cours
          </a>
        </Alert>
      )}

      <Flex flexDirection={['column', 'column', 'row']} gap={10} m={10}>
        <Flex gap={10} flexDirection="column">
          <Box boxShadow={'md'} rounded="md" p={4}>
            {dummyMission.end_date < new Date().toISOString() ? (
              <Badge
                h="fit-content"
                ml="1"
                fontSize="0.8em"
                colorScheme="green"
              >
                Terminé
              </Badge>
            ) : (
              <Badge
                h="fit-content"
                ml="1"
                fontSize="0.8em"
                colorScheme="orange"
              >
                En cours
              </Badge>
            )}
          </Box>
          <Box boxShadow={'md'} rounded="md" p={4}>
            <Flex
              flexDirection="column"
              key={missions?.Client?.id}
              textAlign={'left'}
              borderRadius="10px"
              justifyContent={'start'}
              p="4"
              gap={2}
            >
              <Heading size="md">Client</Heading>
              <Avatar src="https://bit.ly/sage" />
              <Flex ml="3" flexDirection={'column'}>
                <Text fontWeight="bold">{missions?.Client?.full_name}</Text>
                <Text fontSize="sm">{missions?.Client?.email}</Text>
                <Text fontSize="sm">{missions?.Client?.company_name}</Text>
              </Flex>
            </Flex>
          </Box>
          <Flex boxShadow={'md'} rounded="md" p={4} flexDirection={'column'}>
            <Text fontSize={'md'}>Price TTC:</Text>
            <Text fontWeight={'bold'}>{missions?.price_ttc}€</Text>
            <Text fontSize={'md'}>Date limite de rendu:</Text>
            <Text fontWeight={'bold'}>
              {missions?.end_time
                ? moment(missions?.end_time).format('DD/MM/YYYY')
                : ''}
            </Text>
          </Flex>
          <Flex boxShadow={'md'} rounded="md" p={4} flexDirection={'column'}>
            <Link
              href={'http://127.0.0.1:4000/mission/' + id + '/terms'}
              isExternal
            >
              Télécharger le contrat
            </Link>
          </Flex>

          {/* {
            isEnded ?
              (
                <Tooltip label={
                  `${mission.approval !== 2 ? "La mission doit être validée par le client" : ""} ${isLoading ? "La transaction est en cours" : ""} ${!active ? "Vous devez vous connecter à votre wallet" : ""}`
                } hasArrow
                  isDisabled={mission.approval === 2 && !isLoading && active}
                >
                  <Button colorScheme={'orange'} onClick={() => handleEndMission()} isDisabled={mission.approval !== 2 || isLoading || !active}>Terminer la mission</Button>
                </Tooltip>
              ) : (
                <Button colorScheme={'orange'} onClick={() => handleUpdateMission()}>Modifier la mission</Button>
              )
          } */}
        </Flex>
        <Flex>
          <Box boxShadow={'md'} rounded="md" p={4}>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Devis
            </Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Quantité</Th>
                  <Th>Prix</Th>
                  <Th>Description</Th>
                  <Th>Price HT</Th>
                  <Th>Price TTC</Th>
                  <Th>TVA</Th>
                </Tr>
              </Thead>
              <Tbody>
                {missions?.description?.map((quotationLine: any) => {
                  return (
                    <Tr key={quotationLine.id}>
                      <Td>{quotationLine.type}</Td>
                      <Td>{quotationLine.quantity}</Td>
                      <Td>{quotationLine.unit_price}</Td>
                      <Td>{quotationLine.description}</Td>
                      <Td>{quotationLine.priceHT}</Td>
                      <Td>{quotationLine.priceTTC}</Td>
                      <Td>{quotationLine.TVA}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </Flex>
      <Footer />
    </div>
  );
};

export default MissionDetails;
