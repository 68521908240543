import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import {
  Flex,
  Card,
  Heading,
  Button,
  Input,
  FormControl,
  FormLabel,
  IconButton,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';
import { useGetClientByIdQuery } from '../../redux/services/clients';
import { ClientType } from '../../globals';

const ClientDetails = () => {
  const { id } = useParams();

  type DataType = {
    data: ClientType;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  };

  const { data: clients } = useGetClientByIdQuery<DataType>(id);

  let [isUpdatable, setIsUpdatable] = useState<boolean>(true);

  const [companyName, setCompanyName] = useState<string>(
    clients?.company_name ? clients?.company_name : ''
  );
  const [siret, setSiret] = useState<string>(
    clients?.siret ? clients?.siret : ''
  );
  const [fullName, setFullname] = useState<string>(
    clients?.full_name ? clients?.full_name : ''
  );
  const [jobPosition, setJobPosition] = useState<string>(
    clients?.job_position ? clients?.job_position : ''
  );

  const handleClientCreation = useCallback(
    () => (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
    },
    []
  );

  return (
    <>
      <Navbar isLogged={true} />
      <Card p={10} m={10}>
        <Flex flexDirection={'row'} gap={4}>
          <Heading mb={10} size={'xl'}>
            Fiche client {id}
          </Heading>
          <IconButton
            onClick={() =>
              isUpdatable ? setIsUpdatable(false) : setIsUpdatable(true)
            }
            colorScheme="teal"
            aria-label="Call Segun"
            size="md"
            icon={<EditIcon />}
          />
        </Flex>
        <form onSubmit={handleClientCreation}>
          <Flex flexDirection={'column'} gap={4}>
            <FormControl>
              <FormLabel>Nom de la société</FormLabel>
              <Input
                type="text"
                placeholder="Luter"
                value={companyName}
                isDisabled={isUpdatable}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>SIRET</FormLabel>
              <Input
                type="text"
                placeholder="613 694 515 00012"
                value={siret}
                onChange={(e) => setSiret(e.target.value)}
                maxLength={14}
                minLength={14}
                isDisabled={isUpdatable}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Nom & Prénom</FormLabel>
              <Input
                type="text"
                placeholder="Full Name"
                value={fullName}
                isDisabled={isUpdatable}
                onChange={(e) => setFullname(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Fonction</FormLabel>
              <Input
                type="text"
                placeholder="Président"
                value={jobPosition}
                isDisabled={isUpdatable}
                onChange={(e) => setJobPosition(e.target.value)}
              />
            </FormControl>
            <Button
              disabled={
                companyName === '' &&
                siret === '' &&
                siret.length < 14 &&
                fullName === '' &&
                jobPosition === ''
              }
              type="submit"
              variant={'solid'}
              colorScheme={'teal'}
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Card>
      <Footer />
    </>
  );
};

export default ClientDetails;
