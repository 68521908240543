import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';

import { useNavigate } from 'react-router-dom';

import { type SignUpFormFreelancer } from '../../globals';

import { useSignUpFreelanceMutation } from '../../redux/services/auth';

const RegisterFromInfo = ({ onNext, onPrev }: any) => {
  const [choosenSubscription, setChoosenSubscription] = useState('');
  const [whiteLabel, setWhiteLabel] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [society, setSociety] = useState<string>('');
  const [siret, setSiret] = useState<string>('');
  const [tva, setTva] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [usage, setUsage] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const [userSignUp, response] = useSignUpFreelanceMutation();
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
    useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.warn("Passwords don't match", {
        toastId: 'passwordsDontMatch',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    return new Promise<string>((resolve, reject) => {
      const formData: SignUpFormFreelancer = {
        address: address,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone: phone,
        siret: siret,
        tva_number: tva,
        company_name: society,
        white_label: whiteLabel,
      };
      userSignUp({ body: formData })
        .unwrap()
        .then((res: any) => {
          setIsLoading(false);
          navigate('/login');
          resolve('Success');
        })
        .catch((err: any) => {
          console.log(err);
          if (err.status === 400) {
            toast.warn(err.data.error, {
              toastId: 'badEmailOrUserAlreadyExists',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          setIsLoading(false);
          reject(err.data.error ? err.data.error : err);
        })
        .catch((err: any) => {
          console.log(err);
          if (err.status === 400) {
            toast.warn(err.data.error, {
              toastId: 'badEmailOrUserAlreadyExists',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          setIsLoading(false);
          reject(err.data.error ? err.data.error : err);
        });
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="flex h-screen pl-10 overflow-hidden"
      style={{ height: '800px', padding: '30px', paddingTop: 110 }}
    >
      {windowWidth > 768 && (
        <div className="w-2/5 pl-4" style={{ height: '100%' }}>
          <BulleForm
            title="Commencez votre aventure avec nous !"
            subtitle="Antoin Bernard"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec accumsan cursus elementum."
          />
        </div>
      )}
      <div className="pl-5 pr-5 w-full flex flex-col h-full justify-center items-center">
        <ProgressBar percent={75} />
        <div className="p-2 w-full flex flex-col h-full justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full md:w-2/3 rounded-md pl-10"
          >
            <div className="w-full">
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    !isEmailValid ? 'border-red-500' : ''
                  }`}
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsEmailValid(e.target.value !== '');
                  }}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="phone"
                >
                  Numéro de téléphone
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                    !isPhoneValid ? 'border-red-500' : ''
                  }`}
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setIsPhoneValid(e.target.value !== '');
                  }}
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="password"
                >
                  Mot de passe
                </label>
                <div className="relative">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      !isPasswordValid ? 'border-red-500' : ''
                    }`}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setIsPasswordValid(e.target.value.length >= 8);
                    }}
                  />
                  <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <svg
                      className="h-6 w-6 text-gray-400 cursor-pointer"
                      onClick={handlePasswordVisibility}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      )}
                    </svg>
                  </span>
                </div>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirmer le mot de passe
                </label>
                <div className="relative">
                  <input
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                      !isConfirmPasswordValid ? 'border-red-500' : ''
                    }`}
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setIsConfirmPasswordValid(e.target.value !== '');
                    }}
                  />
                  <span className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <svg
                      className="h-6 w-6 text-gray-400 cursor-pointer"
                      onClick={handlePasswordVisibility}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      )}
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={onPrev}
                >
                  Précédent
                </button>
                <button
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    !isEmailValid ? 'disabled' : ''
                  }`}
                  onClick={onNext}
                  disabled={!isEmailValid}
                >
                  Suivant
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterFromInfo;
