import React from 'react';
import LoginForm from '../../Components/LoginForm';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

const Login = () => {
  return (
    <>
      <Navbar isLogged={false} />
      <div className="flex gap-4 flex-col items-center justify-center">
        <LoginForm />
      </div>
      <Footer />
    </>
  );
};

export default Login;
