import React from 'react';
import { RiContactsBookFill } from 'react-icons/ri';
import { BsFillGrid1X2Fill, BsBriefcaseFill } from 'react-icons/bs';
import { IoLogOut } from 'react-icons/io5';
import Logo from '../assets/logo_luter_final.png';
import { Link } from 'react-router-dom';
type Props = {
  username: string;
};

const Sidebar: React.FC<Props> = ({ username }) => {
  return (
    <div className="inset-0 mx-8 mt-8 h-full z-50 w-fit hidden lg:flex">
      {/* Sidebar */}
      <div className="flex flex-col justify-between items-center w-64 h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6">
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="flex items-center justify-center gap-2">
            <img src={Logo} alt="Logo" className="w-10 h-auto" />
            <p className="text-primary text-4xl">luter</p>
          </div>
          <div className="text-center">
            <p className="text-xl mt-8 mb-2">Bonjour,</p>
            <p className="text-primary text-xl mb-4">{username}</p>
          </div>
          <div className="px-4 flex flex-col">
            <ul className="flex flex-col justify-center">
              <li className="mb-8">
                <Link to="/dashboard">
                  <button className="flex items-center justify-center hover:text-blue-500">
                    <BsFillGrid1X2Fill
                      size={18}
                      className="mr-2 text-blue-500"
                    />
                    <span className="text-xl">Dashboard</span>
                  </button>
                </Link>
              </li>
              <li className="mb-8">
                <Link to="/mission">
                  <button className="flex items-center justify-center hover:text-blue-500">
                    <BsBriefcaseFill size={20} className="mr-2 text-blue-500" />
                    <span className="text-xl">Mes missions</span>
                  </button>
                </Link>
              </li>
              <li className="mb-8">
                <Link to="/client">
                  <button className="flex items-center justify-center hover:text-blue-500">
                    <RiContactsBookFill
                      size={24}
                      className="mr-2 text-blue-500"
                    />
                    <span className="text-xl">Mes clients</span>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-4 py-2">
          <button className="flex items-center hover:text-blue-500">
            Se déconnecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
