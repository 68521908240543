import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { autoLogin } from '../redux/feature/userSlice';
import {useGetUserAutoLoginMutation} from "../redux/services/auth";


export const AutoLoginRedirect = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [getUserAutoLogin, response] = useGetUserAutoLoginMutation();
    const user = useSelector((state: any) => state.user);

    async function fetchUser() {
        console.log(searchParams.get('token'));
        let token = searchParams.get('token');
        const res:any = await getUserAutoLogin(token);
        dispatch(autoLogin({user : res.data.user, accessToken  : searchParams.get('token')}));
        console.log(res);
    }
    useEffect(() => {
        fetchUser();
        setTimeout(() => {
            window.location.href = '/dashboard';
        } , 1000);
    }, [dispatch, searchParams]);


    return null;
};