import React, { useState, useEffect } from 'react';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';
import PopUp from '../PopUp';

function RegisterEntrepriseUsage({ onNext, onPrev }: any) {
  const [usage, setUsage] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>(
    'Sequestration de fonds uniquement'
  );
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsage(event.target.value);
    if (event.target.value === 'Sequestration de fonds uniquement') {
      setSubtitle('Sequestration de fonds uniquement');
    } else if (
      event.target.value === 'Sequestration de fonds et accompagnements'
    ) {
      setSubtitle('Sequestration de fonds et accompagnements');
    }
  };

  const handleNext = () => {
    if (usage) {
      onNext();
    } else {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row p-30"
        style={{ height: '100%', padding: 30, paddingTop: '110px' }}
      >
        <div className="w-full md:w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent={25} className="sticky top-0 z-20" />
          <div className="flex flex-col gap-6 pt-10 justify-center h-full">
            <h1 className="text-2xl font-bold">Quel usage prévoyez-vous ?</h1>
            <label className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
              <input
                type="radio"
                className="form-radio"
                value="Sequestration de fonds uniquement"
                onChange={handleRadioChange}
                checked={usage === 'Sequestration de fonds uniquement'}
                required
              />
              <span className="text-lg font-medium pl-2">
                Sequestration de fonds uniquement
              </span>
            </label>
            <label className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
              <input
                type="radio"
                className="form-radio"
                value="Sequestration de fonds et accompagnements"
                onChange={handleRadioChange}
                checked={usage === 'Sequestration de fonds et accompagnements'}
                required
              />
              <span className="text-lg font-medium pl-2">
                Sequestration de fonds et accompagnements
              </span>
            </label>
            <div className="mt-20 text-right flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={onPrev}
              >
                Précédent
              </button>
              <button
                onClick={handleNext}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
        {windowWidth > 768 && (
          <div className="flex flex-col h-full">
            <div className="flex-grow">
              <BulleForm
                title="Laissez-nous vous proposer des solutions sur-mesure"
                subtitle={subtitle}
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec accumsan cursus elementum."
              />
            </div>
            <div className="flex-none mt-auto"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterEntrepriseUsage;
