import React, { useState } from 'react';
import GeneralInfo from '../GeneralInfo';
import PopUp from '../PopUp';
import DashboardLayout from '../../Layout/DashboardLayout';
import ProgressBar from '../ProgressBar';
import { Link } from 'react-router-dom';

function CreateMission({ data, setData, handleNext, handlePrevious }: any) {
  const [usage, setUsage] = useState('');
  const [subtitle, setSubtitle] = useState('Taux de commission');
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState({});

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Choisissez une option"
          text="Veuillez choisir une option avant de continuer."
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row"
        style={{ height: '100%', padding: 30 }}
      >
        <div className="w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent="75" />
          <div className="flex flex-col gap-6" style={{ height: '100%' }}>
            <h1 className="text-2xl font-bold mt-16">
              Détail de votre proposition
            </h1>
            <p>
              Vous pouvez ajouter autant d&apos;éléments que vous le souhaitez.
              En plus d&apos;apporter de la clarté et de la transparence pour
              votre client, un devis détaillé vous permet de légitimer
              l&apos;ensemble de votre prestation en définissant un champ
              d&apos;action précis.
            </p>
          </div>
        </div>
        <div className="w-2/5 pl-4" style={{ height: '100%' }}>
          <GeneralInfo
            subtitle={subtitle}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec acc"
          />
          <div className="mt-20 text-right flex items-center justify-between lg:mx-8 md:mx-4 sm:mx-2">
            <button
              onClick={handlePrevious}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Retour
            </button>
            <button
              onClick={handleNext}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMission;
