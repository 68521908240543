import contractAddress from '../../contracts/contract-address.json';
import { ethers } from 'ethers';
import LockFactory from '../../contracts/LockFactory.json';
import Lock from '../../contracts/Lock.json';

const handleGetContractFund = async (
  provider: any,
  id: string,
  setLockedAmount: any,
  setBalance: any
) => {
  const signer = provider.getSigner();
  const factoryContract = new ethers.Contract(
    contractAddress['Lock'],
    LockFactory.abi,
    signer
  );
  const lockContractAddress = await factoryContract.deployedMissions(id);

  const lockContract = new ethers.Contract(
    lockContractAddress,
    Lock.abi,
    signer
  );

  let lockedAmount = await lockContract.lockedAmount();
  let balance = await provider.getBalance(lockContractAddress);

  setLockedAmount(lockedAmount);
  setBalance(balance);
};

export default handleGetContractFund;
