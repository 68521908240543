import React from 'react';
import { Link } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { BsBriefcaseFill } from 'react-icons/bs';
import { MissionForm } from '../../globals';

const dummyMission = [
  {
    id: 1,
    title: 'Mission 1',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam',
    status: 'En cours',
    client: {
      id: 1,
      name: 'Client 1',
      address: '1 rue de la paix',
      city: 'Paris',
      zipCode: '75000',
      country: 'France',
      email: 'client1@gmail.com',
    },
    startDate: '2021-01-01',
    endDate: '2021-01-01',
    price: 1000,
    currency: 'EUR',
    paymentStatus: 'En attente',
    paymentDate: '2021-01-01',
    paymentMethod: 'Virement',
    paymentReference: '123456789',
    paymentAmount: 1000,
    paymentCurrency: 'EUR',
    createdAt: '2021-01-01',
    updatedAt: '2021-01-01',
    deletedAt: null,
  },
  {
    id: 2,
    title: 'Mission 2',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam',
    status: 'En cours',
    client: {
      id: 2,
      name: 'Client 2',
      address: '1 rue de la paix',
      city: 'Paris',
      zipCode: '75000',
      country: 'France',
      email: 'client1@gmail.com',
    },
    startDate: '2021-01-01',
    endDate: '2021-01-01',
    price: 1000,
    currency: 'EUR',
    paymentStatus: 'En attente',
    paymentDate: '2021-01-01',
    paymentMethod: 'Virement',
    paymentReference: '123456789',
    paymentAmount: 1000,
    paymentCurrency: 'EUR',
    createdAt: '2021-01-01',
    updatedAt: '2021-01-01',
    deletedAt: null,
  },
];

const MissionList = () => {
  type DataTypeMissions = {
    data: MissionForm[];
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
  };

  return (
    <DashboardLayout>
      <div className="flex justify-between items-center">
        <div className="text-4xl">Mes missions</div>
        <Link to="/mission/new">
          <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded">
            <BsBriefcaseFill size={20} className="mr-2 text-white" />
            Créer une mission
          </button>
        </Link>
      </div>
      <div className="h-full bg-creamWhite mt-4 mb-8 rounded-[30px] border border-gray-200 shadow-xl">
        <div className="bg-white rounded-[20px] m-8 p-8">
          <table className="w-full">
            <thead className="bg-whiteGrey text-primary">
              <tr>
                <th className="py-2">MISSIONS</th>
                <th>TÂCHE</th>
                <th>DATE LIMITE</th>
                <th>PROCHAIN PAIEMENT</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {dummyMission.map((mission: any) => (
                <tr key={mission.id}>
                  <td className="px-4 py-2">{mission.title}</td>
                  <td className="px-4">{mission.description}</td>
                  <td className="px-4">{mission.endDate}</td>
                  <td className="px-4">{`${mission.price} ${mission.currency} (${mission.paymentStatus})`}</td>
                  <td className="px-4">{mission.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MissionList;
