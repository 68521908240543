import React, { useState } from 'react';

type Props = {
  title: string;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  style?: React.CSSProperties;
};

const Popup = ({ title, text, onConfirm, onCancel, onClose, style }: Props) => {
  const [showPopup, setShowPopup] = useState(true);

  const handleConfirm = () => {
    onConfirm();
    setShowPopup(false);
  };

  const handleCancel = () => {
    onCancel();
    onClose();
    setShowPopup(false);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${
        showPopup ? '' : 'hidden'
      }`}
      style={style}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50"></div>
      <div className="bg-white rounded-lg p-5 z-10">
        <h2 className="mb-5">{title}</h2>
        <p>{text}</p>
        <div className="flex justify-end mt-5">
          <button
            className="bg-gray-500 text-white px-3 py-1 rounded mr-3"
            onClick={handleCancel}
          >
            Annuler
          </button>
          <button
            className="bg-blue-500 text-white px-3 py-1 rounded"
            onClick={handleConfirm}
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
