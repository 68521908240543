import React from 'react';
import { Link } from 'react-router-dom';
import { BsBriefcaseFill } from 'react-icons/bs';
import { FaStar, FaRegStar } from 'react-icons/fa';
import DashboardLayoutClient from '../Layout/DashboardLayoutClient';
import freelancerPhoto from '../assets/relax_landing.png';

type Props = {};

const FreelancerList = (props: Props) => {
  const MAX_RATING = 5;

  const dummyFreelancer = {
    id: 1,
    name: 'Fabien Lelaitier',
    address: '1 rue de la paix',
    city: 'Paris',
    zipCode: '75000',
    country: 'France',
    email: 'freelancer1@test.com',
    company: 'Company 1',
    notation: 4.5,
    contact: '123-456-7890',
    skills: 'Web Development, Graphic Design',
  };

  const generateRatingStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = MAX_RATING - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <div className="flex items-center justify-center text-yellow-500">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={`full-${index}`} />
        ))}
        {hasHalfStar && <FaRegStar />}
        {[...Array(emptyStars)].map((_, index) => (
          <FaRegStar key={`empty-${index}`} />
        ))}
      </div>
    );
  };

  return (
    <DashboardLayoutClient>
      <div className="flex justify-between items-center">
        <div className="text-4xl">Info du Freelance</div>
      </div>
      <div className="h-full bg-creamWhite mt-4 mb-8 rounded-[30px] border border-gray-200 shadow-xl">
        <div className="bg-white rounded-[20px] m-8 p-8">
          <div className="flex">
            <div className="flex-grow">
              <h2 className="text-2xl font-bold mb-4">
                {dummyFreelancer.name}
              </h2>
              <p>
                <strong>Entreprise:</strong> {dummyFreelancer.company}
              </p>
              <p>
                <strong>Adresse:</strong>{' '}
                {`${dummyFreelancer.address}, ${dummyFreelancer.zipCode} ${dummyFreelancer.city}, ${dummyFreelancer.country}`}
              </p>
              <p>
                <strong>Email:</strong> {dummyFreelancer.email}
              </p>
              <p>
                <strong>Notation:</strong>{' '}
                {generateRatingStars(dummyFreelancer.notation)}
              </p>
              <p>
                <strong>Coordonnées:</strong> {dummyFreelancer.contact}
              </p>
              <p>
                <strong>Compétences:</strong> {dummyFreelancer.skills}
              </p>
              <div className="mt-4">
                <h3 className="text-lg font-bold mb-2">Description:</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse ac velit eget lectus fringilla condimentum id non
                  velit. Phasellus faucibus nulla sit amet nisl finibus, vitae
                  dapibus nulla feugiat.
                </p>
              </div>
            </div>
            <div className="flex-shrink-0">
              <img
                src={freelancerPhoto}
                alt="Photo:"
                className="w-40 h-40 rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayoutClient>
  );
};

export default FreelancerList;
