import React, { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import RegisterUsage from '../../Components/RegisterFormFreelance/RegisterUsage';
import RegisterTarification from '../../Components/RegisterFormFreelance/RegisterTarification';
import RegisterFromInfo from '../../Components/RegisterFormFreelance/RegisterFromInfo';
import RegisterFormInfo2 from '../../Components/RegisterFormFreelance/RegisterFormInfo2';

const RegisterFreelance = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderFormStep = (step: number) => {
    console.log(currentStep);
    switch (step) {
      case 1:
        return <RegisterUsage onNext={goToNextStep} />;
      case 2:
        return (
          <RegisterTarification
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
          />
        );
      case 3:
        return (
          <RegisterFromInfo onPrev={goToPreviousStep} onNext={goToNextStep} />
        );
      case 4:
        return <RegisterFormInfo2 onPrev={goToPreviousStep} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar isLogged={false} />
      {renderFormStep(currentStep)}
      <Footer />
    </div>
  );
};

export default RegisterFreelance;
