import { toast } from 'react-toastify';

const displayWarningToast = (message: string, id?: string) =>
  toast.warn(message, {
    toastId: id,
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });

export default displayWarningToast;
