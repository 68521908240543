import React from 'react';
type Props = {
  children: any;
};
import ClientNavbar from '../Components/ClientNavbar';
import SidebarClient from '../Components/SidebarClient';

const DashboardLayoutClient: React.FC<Props> = ({ children }) => {
  return (
    <main className="min-h-screen z-0 relative">
      <ClientNavbar />
      <div className="flex h-full justify-start w-full h-screen bg-creamWhite">
        <SidebarClient missionname="CrabBird" />
        <div className="mt-32 w-full pr-8">{children}</div>
      </div>
    </main>
  );
};

export default DashboardLayoutClient;
