import React from 'react';
type Props = {
  children: any;
};
import FreelanceNavbar from '../Components/FreelanceNavbar';
import Sidebar from '../Components/Sidebar';

const DashboardLayout: React.FC<Props> = ({ children }) => {
  return (
    <main className="min-h-screen z-0 relative">
      <FreelanceNavbar />
      <div className="flex justify-start w-full h-screen bg-creamWhite">
        <Sidebar username="Daniel TIRRIER" />
        <div className="mt-32 w-full pr-8">{children}</div>
      </div>
    </main>
  );
};

export default DashboardLayout;
