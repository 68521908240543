import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';

import { SignUpFormFreelancer } from '../../globals';
import { useSignUpFreelanceMutation } from '../../redux/services/auth';

const RegisterFromInfo2 = ({ onPrev }: any) => {
  const [choosenSubscription, setChoosenSubscription] = useState('');
  const [whiteLabel, setWhiteLabel] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [society, setSociety] = useState<string>('');
  const [siret, setSiret] = useState<string>('');
  const [tva, setTva] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [usage, setUsage] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const [userSignUp, response] = useSignUpFreelanceMutation();
  const navigate = useNavigate();
  const [isfirstNameValid, setIsConfirmFirstNameValid] =
    useState<boolean>(false);
  const [isLastNameValid, setIsConfirmLastNameValid] = useState<boolean>(false);
  const [isAddressValid, setIsConfirmAddressValid] = useState<boolean>(false);
  const [isZipValid, setIsConfirmZipValid] = useState<boolean>(false);
  const [isTvaValid, setIsConfirmTvaValid] = useState<boolean>(false);
  const [isSiretValid, setIsConfirmSiretValid] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.warn("Passwords don't match", {
        toastId: 'passwordsDontMatch',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    setIsLoading(true);
    return new Promise<string>((resolve, reject) => {
      const formData: SignUpFormFreelancer = {
        address: address,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone: phone,
        siret: siret,
        tva_number: tva,
        company_name: society,
        white_label: whiteLabel,
      };
      userSignUp({ body: formData })
        .unwrap()
        .then((res: any) => {
          setIsLoading(false);
          navigate('/login');
          resolve('Success');
        })
        .catch((err: any) => {
          console.log(err);
          if (err.status === 400) {
            toast.warn(err.data.error, {
              toastId: 'badEmailOrUserAlreadyExists',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          }
          setIsLoading(false);
          reject(err.data.error ? err.data.error : err);
        });
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      <div
        className="p-2 w-full flex flex-col md:flex-row p-30"
        style={{ height: '800px', padding: 30, paddingTop: '110px' }}
      >
        <form
          onSubmit={handleSubmit}
          className="flex flex-col md:w-2/3 rounded-md pr-10"
        >
          <ProgressBar percent={100} className="absolute top-0 left-0" />
          <div className="grid gap-6 mb-6 md:grid-cols-2 py-10">
            <div className="mb-4">
              <label htmlFor="firstName" className="block font-bold mb-2">
                Nom
              </label>
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setIsConfirmFirstNameValid(e.target.value !== '');
                }}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  !isfirstNameValid ? 'border-red-500' : ''
                }`}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="block font-bold mb-2">
                Prénom
              </label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setIsConfirmLastNameValid(e.target.value !== '');
                }}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  !isLastNameValid ? 'border-red-500' : ''
                }`}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="address" className="block font-bold mb-2">
                Adresse postale
              </label>
              <input
                type="text"
                name="address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setIsConfirmAddressValid(e.target.value !== '');
                }}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  !isAddressValid ? 'border-red-500' : ''
                }`}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="zip" className="block font-bold mb-2">
                Code postal
              </label>
              <input
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => {
                  setZip(e.target.value);
                  setIsConfirmZipValid(e.target.value !== '');
                }}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  !isZipValid ? 'border-red-500' : ''
                }`}
              />
            </div>
          </div>
          <div className="border mx-10 mb-10"></div>
          <div className="mb-4">
            <label htmlFor="tva" className="block font-bold mb-2">
              Numéro de TVA
            </label>
            <input
              type="text"
              name="tva"
              value={tva}
              onChange={(e) => {
                setTva(e.target.value);
                setIsConfirmTvaValid(e.target.value !== '');
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                !isTvaValid ? 'border-red-500' : ''
              }`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="siret" className="block font-bold mb-2">
              Numéro de SIRET
            </label>
            <input
              type="text"
              name="sire"
              value={siret}
              onChange={(e) => {
                setSiret(e.target.value);
                setIsConfirmSiretValid(e.target.value !== '');
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                !isSiretValid ? 'border-red-500' : ''
              }`}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={onPrev}
            >
              Précédent
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Suivant
            </button>
          </div>
        </form>
        {windowWidth > 768 && (
          <div className="w-full md:w-2/5 pl-4" style={{ height: '100%' }}>
            <BulleForm
              title="Dernière ligne droite !"
              subtitle="Utilisation des données personnelles"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tincidunt libero nec varius pellentesque. Suspendisse potenti. Donec accumsan cursus elementum."
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterFromInfo2;
