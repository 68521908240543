import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoV2 from '../assets/logo-v2.png';
import { BiChevronUp, BiChevronDown, BiMenu, BiX } from 'react-icons/bi';
import Button from './Button';

type NavbarProps = {
  isLogged: boolean;
};

type DropDownMenuProps = {
  label: string;
  open: boolean;
  onClick?: () => void;
};

const DropDownMenu = ({ label, open, onClick }: DropDownMenuProps) => {
  return (
    <span className="text-white flex justify-between items-center cursor-pointer">
      <p
        className="
          font-light
          text-xl
        "
      >
        {label}
      </p>
      <span className="hover:rotate-90 transition-all duration-300">
        {open ? <BiChevronUp size={26} /> : <BiChevronDown size={26} />}
      </span>
    </span>
  );
};

const Navbar = ({ isLogged }: NavbarProps) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  const renderDropDownMenu = (label: string) => (
    <span
      className="text-white flex justify-between items-center cursor-pointer"
      onClick={handleMenuClick}
    >
      <p className="font-light text-xl">{label}</p>
      <span className="hover:rotate-90 transition-all duration-300">
        {isMenuOpen ? <BiChevronUp size={26} /> : <BiChevronDown size={26} />}
      </span>
    </span>
  );

  return (
    <nav className="fixed left-0 w-full bg-blue-300 bg-opacity-50 z-50">
      <div className="container mx-auto px-8 py-4 flex justify-between items-center">
        <img src={LogoV2} alt="logo" className="w-24 md:w-32 lg:w-40" />
        <div className="hidden md:flex items-center space-x-4">
          {renderDropDownMenu('Solutions')}
          {renderDropDownMenu('Offres et tarifs')}
          {renderDropDownMenu('À propos')}
        </div>
        <div className="flex md:hidden items-center">
          <button className="focus:outline-none" onClick={handleMenuToggle}>
            {isMenuOpen ? <BiX size={32} /> : <BiMenu size={32} />}
          </button>
        </div>
        <div
          className={`${
            isMenuOpen ? 'flex' : 'hidden'
          } md:hidden flex-col w-full pt-4 pb-2 px-4 bg-blue-300 bg-opacity-50`}
        >
          {renderDropDownMenu('Solutions')}
          {renderDropDownMenu('Offres et tarifs')}
          {renderDropDownMenu('À propos')}
          <div className="flex justify-around items-center pt-4">
            <Button
              label="Se connecter"
              onClick={() => navigate('/login')}
              data-testid="sign-in-button"
            />
            <Button
              label="Créer un compte"
              primary
              onClick={() => navigate('/register')}
              data-testid="sign-up-button"
            />
          </div>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          <Button
            label="Se connecter"
            onClick={() => navigate('/login')}
            data-testid="sign-in-button"
          />
          <Button
            label="Créer un compte"
            primary
            onClick={() => navigate('/register')}
            data-testid="sign-up-button"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
