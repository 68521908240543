import React from 'react';
import { CiSettings, CiBellOn } from 'react-icons/ci';
import { HiMagnifyingGlass } from 'react-icons/hi2';

const FreelanceNavbar = () => {
  return (
    <nav className="flex gap-6 xl:gap-0 justify-end lg:justify-between items-center px-4 py-2 z-0 absolute top-0 w-full h-24 bg-gradient">
      <div className="flex-1 flex justify-end xl:justify-center">
        <div className="md:w-full max-w-md flex items-center border border-gray-300 rounded-full px-4 py-2 bg-white">
          <input
            type="text"
            placeholder="Search"
            className="w-full outline-none bg-white"
          />
          <HiMagnifyingGlass />
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <button className="rounded-full bg-gray-100 hover:bg-gray-200 p-2">
          <CiSettings size={24} />
        </button>
        <button className="rounded-full bg-gray-100 hover:bg-gray-200 p-2">
          <CiBellOn size={24} />
        </button>
      </div>
    </nav>
  );
};

export default FreelanceNavbar;
