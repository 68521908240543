import React from 'react';

type Props = {
  primary?: boolean;
  size?: 'small' | 'medium' | 'large';
  label: string;
  onClick: () => void;
};

const Button = ({
  primary = false,
  size = 'medium',
  label,
  ...props
}: Props) => {
  return (
    <button
      type="button"
      className={`
            ${
              primary
                ? 'bg-white hover:opacity-80 text-primary'
                : 'bg-transparent border-white border-2 hover:opacity-80 text-white'
            }
            font-bold py-2 px-4 transition-all rounded-lg min-w-fit
            ${
              size === 'small'
                ? 'text-sm'
                : size === 'large'
                ? 'text-lg'
                : 'text-base'
            }
        `}
      {...props}
    >
      {label}
    </button>
  );
};

export default Button;
