import React from 'react';
import Logo from '../assets/white_logo.png';
import LogoV2 from '../assets/logo-v2.png';

type Props = {
  subtitle: string;
  text: string;
};

const MissionBulle = ({ subtitle, text }: Props) => {
  return (
    <div className="flex flex-col pl-4 bg-gradient-to-br from-blue-600 bg-black relative rounded-3xl h-full lg:mx-8 md:mx-4 sm:mx-2 overflow-hidden">
      <div className="flex flex-col h-full p-4 justify-between">
        <div className="flex justify-end">
          <div className="p-2 flex flex-col items-center">
            <img src={Logo} alt="logo" style={{ width: '30px' }} />
            <img src={LogoV2} alt="logo" style={{ width: '40px' }} />
          </div>
        </div>
        <div className="flex-grow flex items-center overflow-hidden">
          <div className="text-center w-full">
            <h2 className="text-white text-4xl text-center mb-40 lg:text-4xl md:text-2xl sm:text-xl">
              Besoin d&apos;une recommandation ?
            </h2>
            <button className="justify-center hover:bg-slate-200 py-1 px-8 text-primary rounded-sm bg-white mb-40">
              Remplir le formulaire
            </button>
            <div className="flex flex-col md:flex-row md:justify-center">
              <div className="bg-white/50 back-blur-md rounded-lg px-4 py-2 md:pb-5 md:mr-5 md:max-w-xs">
                <h3 className="text-primary font-bold text-lg text-center pb-5 md:pb-0">
                  {subtitle}
                </h3>
                <p className="text-sm mt-1">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionBulle;
