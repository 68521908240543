import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { type QuotationLine, ClientType } from '../../../globals';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Alert,
  AlertIcon,
  Badge,
  Avatar,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  CircularProgress,
  AlertDescription,
  AlertTitle,
} from '@chakra-ui/react';

import { useWeb3React } from '@web3-react/core';
import ClientNavbar from '../../../Components/ClientNavbar';

import contractHandleEndMission from '../../../utils/contract/handleEndMission';
import contractHandleGetContractFund from '../../../utils/contract/handleGetContractFund';
import contractHandleAddContractFund from '../../../utils/contract/handleAddContractFund';

const dummyClients: ClientType[] = [
  {
    id: 1,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
  {
    id: 2,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
  {
    id: 3,
    wallet_address: '0x5586CFb2f128Edb244B54AdC63896C0766429030',
    company_name: 'Company 1',
    siret: '123456789',
    full_name: 'John Doe',
    job_position: 'CEO',
    email: 'jhondoe@test.com',
    tva_number: '123456789',
  },
];

const dummyQuotation: QuotationLine[] = [
  {
    id: 0,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 1,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 2,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 3,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 4,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 5,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
  {
    id: 6,
    type: 'Acompte',
    quantity: 0,
    unit_price: 0,
    description: 'Acompte',
    priceHT: 0,
    priceTTC: 0,
    TVA: 20,
  },
];

const dummyMission = {
  id: 1,
  client: dummyClients[0],
  quotation: dummyQuotation,
  price_ttc: 120,
  end_date: '2022-10-12',
  approval: 2,
};

const ClientDashboard = () => {
  const { active, library: provider } = useWeb3React();

  const [isLoading, setIsLoading] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const { id } = useParams();

  const isEnded = dummyMission.end_date < new Date().toISOString();

  const mission = dummyMission;

  const handleUpdateMission = useCallback(() => {
    console.log('Update mission');
  }, []);

  const handleEndMission = useCallback(async () => {
    setIsLoading(true);

    try {
      contractHandleEndMission(provider, id as string, setTxHash, setIsSuccess);
    } catch (e: any) {
      setError(e.message);
      console.log('error: ', e);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
    setIsLoading(false);
  }, [provider, id]);

  const checkContractFund = useCallback(async () => {
    let lockedAmount = 0;
    let balance = 0;
    const setLockedAmount = (amount: number) => (lockedAmount = amount);
    const setBalance = (amount: number) => (balance = amount);

    try {
      contractHandleGetContractFund(
        provider,
        id as string,
        setLockedAmount,
        setBalance
      );
    } catch (e: any) {
      setError(e.message);
      console.log('error: ', e);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
    return lockedAmount >= balance;
  }, [provider, id]);

  const handleAddFund = useCallback(async () => {
    setIsLoading(true);

    if (await checkContractFund()) {
      setError('Contract is already funded');
      setTimeout(() => {
        setError('');
        setIsLoading(false);
      }, 5000);
      return;
    }

    try {
      contractHandleAddContractFund(
        provider,
        id as string,
        setTxHash,
        setIsSuccess
      );
    } catch (e: any) {
      setError(e.message);
      console.log('error: ', e);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
    setIsLoading(false);
    setTxHash('');
  }, [provider, id, checkContractFund]);

  return (
    <div>
      <ClientNavbar />
      <Text fontSize="4xl" textAlign="center" fontWeight={'bold'}>
        Mission #{id}
      </Text>
      {error && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      {isSuccess && (
        <Alert status="success">
          <AlertIcon />
          La mission a bien été approvisionée
        </Alert>
      )}
      {isLoading && !error && (
        <Alert status="info">
          <AlertIcon />
          En attente de confirmation de la transaction
        </Alert>
      )}
      {!isSuccess && txHash && (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>Transaction envoyée</AlertTitle>
          <AlertDescription>
            Vous pouvez suivre l&apos;avancement de la transaction sur{' '}
            <a
              className="underline"
              href={`https://goerli.etherscan.io/tx/${txHash}`}
            >
              etherscan
            </a>
          </AlertDescription>
        </Alert>
      )}

      <Flex flexDirection={['column', 'column', 'row']} gap={10} m={10}>
        <Flex gap={10} flexDirection="column">
          <Box boxShadow={'md'} rounded="md" p={4}>
            {dummyMission.end_date < new Date().toISOString() ? (
              <Badge
                h="fit-content"
                ml="1"
                fontSize="0.8em"
                colorScheme="green"
              >
                Terminé
              </Badge>
            ) : (
              <Badge
                h="fit-content"
                ml="1"
                fontSize="0.8em"
                colorScheme="orange"
              >
                En cours
              </Badge>
            )}
          </Box>
          <Box boxShadow={'md'} rounded="md" p={4}>
            <Flex
              flexDirection="column"
              key={dummyMission.client.id}
              textAlign={'left'}
              borderRadius="10px"
              justifyContent={'start'}
              p="4"
              gap={2}
            >
              <Heading size="md">Client</Heading>
              <Avatar src="https://bit.ly/sage-adebayo" />
              <Flex ml="3" flexDirection={'column'}>
                <Text fontWeight="bold">{dummyMission.client.full_name}</Text>
                <Text fontSize="sm">{dummyMission.client.email}</Text>
                <Text fontSize="sm">{dummyMission.client.company_name}</Text>
              </Flex>
            </Flex>
          </Box>
          <Flex boxShadow={'md'} rounded="md" p={4} flexDirection={'column'}>
            <Text fontSize={'md'}>Price TTC:</Text>
            <Text fontWeight={'bold'}>{dummyMission.price_ttc}€</Text>
            <Text fontSize={'md'}>Date limite de rendu:</Text>
            <Text fontWeight={'bold'}>{dummyMission.end_date}</Text>
          </Flex>
          <Tooltip
            label={'Vous devez vous connecter à votre wallet'}
            hasArrow
            isDisabled={active}
          >
            <Button
              isDisabled={!active}
              colorScheme={'orange'}
              onClick={() => handleAddFund()}
            >
              {isLoading ? (
                <CircularProgress isIndeterminate size="24px" color="orange" />
              ) : (
                'Approvisioner la mission'
              )}
            </Button>
          </Tooltip>
          {isEnded ? (
            <Tooltip
              label={`${
                mission.approval !== 2
                  ? 'La mission doit être validée par le client'
                  : ''
              } ${isLoading ? 'La transaction est en cours' : ''} ${
                !active ? 'Vous devez vous connecter à votre wallet' : ''
              }`}
              hasArrow
              isDisabled={mission.approval === 2 && !isLoading && active}
            >
              <Button
                colorScheme={'orange'}
                onClick={() => handleEndMission()}
                isDisabled={mission.approval !== 2 || isLoading || !active}
              >
                Terminer la mission
              </Button>
            </Tooltip>
          ) : (
            <Button
              colorScheme={'orange'}
              onClick={() => handleUpdateMission()}
            >
              Modifier la mission
            </Button>
          )}
        </Flex>
        <Flex>
          <Box boxShadow={'md'} rounded="md" p={4} h="fit-content">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Devis
            </Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Quantité</Th>
                  <Th>Prix</Th>
                  <Th>Description</Th>
                  <Th>Price HT</Th>
                  <Th>Price TTC</Th>
                  <Th>TVA</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dummyMission.quotation.map((quotationLine: QuotationLine) => (
                  <Tr key={quotationLine.id}>
                    <Td>{quotationLine.type}</Td>
                    <Td>{quotationLine.quantity}</Td>
                    <Td>{quotationLine.unit_price}</Td>
                    <Td>{quotationLine.description}</Td>
                    <Td>{quotationLine.priceHT}</Td>
                    <Td>{quotationLine.priceTTC}</Td>
                    <Td>{quotationLine.TVA}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default ClientDashboard;
