import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './Pages/App';
import Register from './Pages/Authentification/Register';
import Login from './Pages/Authentification/Login';
import RegisterAPI from './Pages/Authentification/RegisterAPI';
import RegisterFreelance from './Pages/Authentification/RegisterFreelance';
import Dashboard from './Pages/Dashboard';
import './styles.css';
import { ToastContainer } from 'react-toastify';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DashboardClient from './Pages/DashboardClient';
import InfoFreelance from './Components/InfoFreelance';
import 'react-toastify/dist/ReactToastify.css';

import {
  ThemeProvider,
  CSSReset,
  ColorModeProvider,
  theme,
} from '@chakra-ui/react';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import MissionList from './Pages/Mission/MissionList';
import MissionDetails from './Pages/Mission/MissionDetails';
import UpdateMission from './Pages/Mission/UpdateMission';
import CreateMission from './Pages/Mission/CreateMission';

import CreateClient from './Pages/Client/CreateClient';
import ClientList from './Pages/Client/ClientList';
import ClientDetails from './Pages/Client/ClientDetails';
import UpdateClient from './Pages/Client/UpdateClient';

import ClientMissionDetails from './Pages/ClientsJourney/ClientMissionDetails';

import ClientDashboard from './Pages/Client/Mission/ClientDashboard';

import { AutoLoginRedirect } from './Components/AutoLoginRedirect';

import Profile from './Pages/Profile/Profile';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

const ErrorComponent = () => (
  <div>
    <h1>Oops! Something went wrong!</h1>
    <p>Sorry, there was an error with the page you are trying to access.</p>
  </div>
);

const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider);
  //   library.pollingInterval = 12000;
  return library;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/register/api',
    element: <RegisterAPI />,
  },
  {
    path: '/register/freelance',
    element: <RegisterFreelance />,
  },
  {
    path: '/autoLoginRedirect',
    element: <AutoLoginRedirect />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/dashboard/client',
    element: <DashboardClient />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/mission',
    element: <MissionList />,
  },
  {
    path: '/mission/:id',
    element: <MissionDetails />,
  },
  {
    path: '/mission/:id/update',
    element: <UpdateMission />,
  },
  {
    path: '/mission/new',
    element: <CreateMission />,
  },
  {
    path: '/client/new',
    element: <CreateClient />,
  },
  {
    path: '/client/',
    element: <ClientList />,
  },
  {
    path: '/dashboard/infofrellance',
    element: <InfoFreelance />,
  },
  {
    path: '/client/:id',
    element: <ClientDetails />,
  },
  {
    path: '/client/:id/update',
    element: <UpdateClient />,
  },
  {
    path: '/client/dashboard/:id',
    element: <ClientDashboard />,
  },
  {
    path: '/client/mission/details/:id',
    element: <ClientMissionDetails />,
  },
  {
    path: '*',
    element: <ErrorComponent />,
  },
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ColorModeProvider>
              <CSSReset />
              <RouterProvider router={router} />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </ColorModeProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Web3ReactProvider>
  </StrictMode>
);
