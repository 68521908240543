import contractAddress from '../../contracts/contract-address.json';
import { ethers } from 'ethers';
import LockFactory from '../../contracts/LockFactory.json';
import Lock from '../../contracts/Lock.json';

const handleAddContractFund = async (
  provider: any,
  id: string,
  setTxHash: any,
  setIsSuccess: any
) => {
  const signer = provider.getSigner();
  const factoryContract = new ethers.Contract(
    contractAddress['Lock'],
    LockFactory.abi,
    signer
  );

  const lockContractAddress = await factoryContract.deployedMissions(id);

  const lockContract = new ethers.Contract(
    lockContractAddress,
    Lock.abi,
    signer
  );

  const lockedAmount = await lockContract.lockedAmount();

  const tx = await lockContract.deposit({ value: lockedAmount });

  setTxHash(tx.hash);
  tx.wait().then(function (receipt: any) {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 5000);
  });
};

export default handleAddContractFund;
