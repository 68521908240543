import React from 'react';
import DashboardLayout from '../../Layout/DashboardLayout';
import { BsBriefcaseFill } from 'react-icons/bs';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const dummyClients = [
  {
    id: 1,
    name: 'Client 1',
    address: '1 rue de la paix',
    city: 'Paris',
    zipCode: '75000',
    country: 'France',
    email: 'test@test.com',
    company: 'Company 1',
    notation: 4.5,
  },
  {
    id: 2,
    name: 'Client 2',
    address: '1 rue de la paix',
    city: 'Paris',
    zipCode: '75000',
    country: 'France',
    email: 'test@test.com',
    company: 'Company 2',
    notation: 4,
  },
  {
    id: 3,
    name: 'Client 3',
    address: '1 rue de la paix',
    city: 'Paris',
    zipCode: '75000',
    country: 'France',
    email: 'test@test.com',
    company: 'Company 3',
    notation: 5,
  },
  {
    id: 4,
    name: 'Client 4',
    address: '1 rue de la paix',
    city: 'Paris',
    zipCode: '75000',
    country: 'France',
    email: 'test@test.com',
    company: 'Company 4',
    notation: 3,
  },
  {
    id: 5,
    name: 'Client 5',
    address: '1 rue de la paix',
    city: 'Paris',
    zipCode: '75000',
    country: 'France',
    email: 'test@test.com',
    company: 'Company 5',
    notation: 4.5,
  },
];

const ClientList = () => {
  const MAX_RATING = 5;
  const generateRatingStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const emptyStars = MAX_RATING - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <div className="flex items-center justify-center text-yellow-500">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={`full-${index}`} />
        ))}
        {hasHalfStar && <FaRegStar />}
        {[...Array(emptyStars)].map((_, index) => (
          <FaRegStar key={`empty-${index}`} />
        ))}
      </div>
    );
  };

  return (
    <DashboardLayout>
      <div className="flex justify-between items-center">
        <div className="text-4xl">Mes clients</div>
        <Link to="/client/new">
          <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded">
            <BsBriefcaseFill size={20} className="mr-2 text-white" />
            Créer un client
          </button>
        </Link>
      </div>
      <div className="h-full bg-creamWhite mt-4 mb-8 rounded-[30px] border border-gray-200 shadow-xl">
        <div className="bg-white rounded-[20px] m-8 p-8">
          <table className="w-full">
            <thead className="bg-whiteGrey text-primary">
              <tr>
                <th className="py-2">CLIENTS</th>
                <th>ENTREPRISE</th>
                <th>ADRESSE</th>
                <th>EMAIL</th>
                <th>NOTATION</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {dummyClients.map((client) => (
                <tr key={client.id}>
                  <td className="px-4 py-2">{client.name}</td>
                  <td className="px-4">{client.company}</td>
                  <td className="px-4">{`${client.address}, ${client.zipCode} ${client.city}`}</td>
                  <td className="px-4">{client.email}</td>
                  <td className="px-4">
                    {generateRatingStars(client.notation)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ClientList;
