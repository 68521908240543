import React, { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import RegisterSize from '../../Components/RegisterFormAPI/RegisterSize';
import RegisterEntrpriseUsage from '../../Components/RegisterFormAPI/RegisterEntrepriseUsage';
import RegisterEntrpriseTarification from '../../Components/RegisterFormAPI/RegisterEntrepriseTarification';
import RegisterEntrpriseFromInfo from '../../Components/RegisterFormAPI/RegisterEntrepriseFromInfo';
import RegisterEntrpriseFromInfo2 from '../../Components/RegisterFormAPI/RegisterEntrpriseFormInfo2';

const RegisterFreelance = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  const renderFormStep = (step: number) => {
    switch (step) {
      case 1:
        return <RegisterSize onNext={goToNextStep} />;
      case 2:
        return (
          <RegisterEntrpriseUsage
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
          />
        );
      case 3:
        return (
          <RegisterEntrpriseTarification
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
          />
        );
      case 4:
        return (
          <RegisterEntrpriseFromInfo
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
          />
        );
      case 5:
        return <RegisterEntrpriseFromInfo2 onPrev={goToPreviousStep} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar isLogged={false} />
      {renderFormStep(currentStep)}
      <Footer />
    </div>
  );
};

export default RegisterFreelance;
