import React from 'react';
import { Link } from 'react-router-dom';
import IncomeChart from '../Components/IncomeChart';
import DashboardLayout from '../Layout/DashboardLayout';
import { useSelector } from 'react-redux';
import { BsBriefcaseFill } from 'react-icons/bs';

const Dashboard = () => {
  const incomeData = [
    { month: 'Janvier', income: 1000 },
    { month: 'Fevrier', income: 2000 },
    { month: 'Mars', income: 1500 },
    { month: 'Avril', income: 3000 },
    { month: 'Mai', income: 2500 },
    { month: 'Juin', income: 3500 },
  ];

  return (
    <DashboardLayout>
      <div className="flex justify-between items-center">
        <div className="text-4xl md:ml-8">Dashboard</div>
        <Link to="/mission/new">
          <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded">
            <BsBriefcaseFill size={20} className="mr-2 text-white" />
            Créer une mission
          </button>
        </Link>
      </div>
      <div className="h-full bg-creamWhite mt-4 mb-8 rounded-[30px] border border-gray-200 shadow-xl md:ml-8">
        <div className="flex m-8">
          <div className="bg-white rounded-[20px] p-4 mr-4 flex-1 relative">
            <div className="text-primary mb-2">Chiffre d&apos;affaires</div>
            <div className="text-primary text-[40px] font-bold">121.234€</div>
            <div className="absolute top-4 right-4 bg-red-300 text-red-700 px-2 py-1 rounded-[30px]">
              -4%
            </div>
          </div>
          <div className="bg-white rounded-[20px] p-4 ml-4 flex-1 relative">
            <div className="text-primary mb-2">Devis</div>
            <div className="text-primary text-[40px] font-bold">51.234€</div>
            <div className="absolute top-4 right-4 bg-green-300 text-green-700 px-2 py-1 rounded-[30px]">
              +215%
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[20px] p-4 mr-8 ml-8 flex-1 relative">
          <IncomeChart data={incomeData} />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
