import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

import {
  ButtonGroup,
  IconButton,
  Flex,
  Editable,
  Input,
  useEditableControls,
  EditablePreview,
  EditableInput,
} from '@chakra-ui/react';

import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';

const UpdateMission = () => {
  const { id } = useParams();

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="confirm"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="cancel"
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          aria-label="edit"
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  return (
    <>
      <Navbar isLogged={true} />
      UpdateMission {id}
      <Editable
        textAlign="center"
        defaultValue="Test"
        fontSize="2xl"
        isPreviewFocusable={false}
      >
        <EditablePreview />
        <EditableInput />
        <EditableControls />
      </Editable>
      <Footer />
    </>
  );
};

export default UpdateMission;
