import { api } from './api';
import { SignUpFormCompany, SignUpFormFreelancer } from '../../globals';

const endpoints = (builder: any) => ({
  signUpCompany: builder.mutation({
    query: ({ body }: { body: SignUpFormCompany }) => ({
      url: `auth/register/company`,
      method: 'POST',
      body: body,
    }),
    providesTags: ['User'],
  }),
  signUpFreelance: builder.mutation({
    query: ({ body }: { body: SignUpFormFreelancer }) => ({
      url: `auth/register/freelance`,
      method: 'POST',
      body: body,
    }),
    providesTags: ['User'],
  }),
  signIn: builder.mutation({
    query: ({
      email: email,
      password: password,
    }: {
      email: string;
      password: string;
    }) => ({
      url: `auth/login`,
      method: 'POST',
      body: {
        email: email,
        password: password,
      },
    }),
  }),
  getUserAutoLogin: builder.mutation({
    query: (accessToken: string) => ({
      url: `auth/getUserAutoLogin`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    providesTags: ['User'],
  }),
});

export const userApi = api.injectEndpoints({ endpoints });
export const {
  useSignUpCompanyMutation,
  useSignUpFreelanceMutation,
  useSignInMutation,
  useGetUserAutoLoginMutation,
} = userApi;

export const selectCurrentUser = (state: any) => state.api.data;
