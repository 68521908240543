import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

const UpdateClient = () => {
  const { id } = useParams();
  return (
    <div>
      <Navbar isLogged={true} />
      UpdateClient {id}
      <Footer />
    </div>
  );
};

export default UpdateClient;
