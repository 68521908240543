import { toast } from 'react-toastify';

const displaySuccessToast = (message: string, id?: string) =>
  toast.success(message, {
    toastId: id,
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

export default displaySuccessToast;
