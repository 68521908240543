import React, { ReactNode } from 'react';

import { VisuallyHidden, useColorModeValue, Link } from '@chakra-ui/react';

import { FaTwitter, FaLinkedin } from 'react-icons/fa';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return <p className="font-medium text-lg mb-2">{children}</p>;
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <Link
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Link>
  );
};

export default function LargeWithAppLinksAndSocial() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gradient-to-l from-blue-400 bg-blue-600 text-white text-center py-10 ">
      <div className="container mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 justify-items-center">
        <div className="flex flex-col overflow-hidden">
          <ListHeader>Entreprise</ListHeader>
          <a href="#" className="hover:underline">
            À propos de nous
          </a>
          <a href="#" className="hover:underline">
            Nous contacter
          </a>
        </div>

        <div className="flex flex-col">
          <ListHeader>Support</ListHeader>
          <a href="#" className="hover:underline">
            Centre d&apos;aide
          </a>
          <a href="#" className="hover:underline">
            Centre de sécurité
          </a>
          <a href="#" className="hover:underline">
            Règles de la communauté
          </a>
        </div>

        <div className="flex flex-col">
          <ListHeader>Juridique</ListHeader>
          <a href="#" className="hover:underline">
            Politique de cookies
          </a>
          <a href="#" className="hover:underline">
            Politique de confidentialité
          </a>
          <a href="#" className="hover:underline">
            Conditions d&apos;utilisation
          </a>
        </div>
      </div>
      <div className="border-t border-gray-200 mt-8">
        <div className="container mx-auto py-4 flex flex-col md:flex-row md:items-center md:justify-between">
          <p className="text-sm text-gray-50">
            ©{currentYear} Luter. All rights reserved
          </p>
          <div className="flex mt-4 md:mt-0">
            <SocialButton
              label="Twitter"
              href="https://twitter.com/LuterFreelance"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <FaTwitter />
              </svg>
            </SocialButton>
            <SocialButton
              label="Linkedin"
              href="https://www.linkedin.com/company/luter-freelance/"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <FaLinkedin />
              </svg>
            </SocialButton>
          </div>
        </div>
      </div>
    </footer>
  );
}
``;
