import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

import { Flex, Text, Avatar, Box } from '@chakra-ui/react';

import { useSelector } from 'react-redux';

const Profile = () => {
  const { user } = useSelector((state: any) => state.user);

  return (
    <div>
      <Navbar isLogged={true} />
      <Flex margin={10} direction="column">
        <Text fontSize="4xl" textAlign="start" fontWeight={'bold'}>
          Profile
        </Text>
        <Flex direction="column">
          <Box w="100%" p={4} borderWidth="1px" borderRadius="lg" mt={4}>
            <Flex>
              <Avatar
                size="2xl"
                name={user?.name}
                src="https://bit.ly/broken-link"
                mr={4}
              />
              <Flex direction="column">
                <Box fontSize="md">
                  <Text fontWeight={'bold'}>E-mail: </Text>
                  {user?.email}
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Box w="100%" p={4} borderWidth="1px" borderRadius="lg" mt={4}>
            <Flex direction="column">
              <Box fontSize="md">
                <Text fontSize="xl" fontWeight="bold">
                  Votre société
                </Text>
                <Text fontWeight={'bold'}>SIRET: </Text>
                {user?.siret}
                <Text fontWeight={'bold'}>Numéro TVA: </Text>
                {user?.tva_number}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Footer />
    </div>
  );
};

export default Profile;
